import { Dispatch, SetStateAction, createContext, useState } from 'react';
import AuthAxios from '../axios';
import { User } from '../types';
import { AxiosError, AxiosResponse } from 'axios';

export type CollaboratorCode = 'SALUSCARE' | 'DBLIFE';

type CollaborationContextState = {
  collaboratorId?: string;
  collaboratorCode?: CollaboratorCode;
  collaborateUserId?: string;
  orderNumber?: string;
  existingUser?: boolean;
  // couponNoticed: boolean;
  // currentSubscription: boolean;
  // productId: string;
  couponAutoUsed: boolean;
  usedCouponId?: number;
  usedCouponCode?: string;
  usedCouponName?: string;
  error?: boolean;
  message?: string;
  isSurveyFinished: boolean;
  surveyData?: {
    surveyResult?: object; // DB생명 설문 결과
    referer?: string;
  };
  hasNoticed: boolean;
};
type CollaborationContextType = {
  state: CollaborationContextState;
  setState: Dispatch<SetStateAction<CollaborationContextState>>;
  createCollaborateUser: (user: User) => Promise<void>;
  linkUserToCollaborateUser: (user: User) => Promise<void>;
  autoUseRegisteredOrderedCoupon: (user: User) => Promise<void>;
  autoUseFreeCoupon: (user: User) => Promise<void | {
    error?: boolean;
    message?: string;
    autoUsed?: boolean;
    couponId?: string;
    couponCode?: string;
    couponName?: string;
  }>;
};

export const CollaborationContext = createContext<CollaborationContextType>({} as CollaborationContextType);

export const CollaborationContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState<CollaborationContextState>({
    couponAutoUsed: false,
    error: false,
    isSurveyFinished: false,
    hasNoticed: false,
  });

  const createCollaborateUser = async (user: User) => {
    const response = await AuthAxios.post(`/web/collaboration/user`, {
      collaboratorCode: state.collaboratorCode,
      userId: user.id,
    }).catch((error) => {
      console.error(error);
    });

    setState({
      ...state,
      existingUser: response ? response.data.existingUser : false,
    });
  };

  const linkUserToCollaborateUser = async (user: User) => {
    console.log('link !!!! ');
    console.log(state);
    console.log(user);
    if (!state.collaboratorCode) {
      return;
    }

    const response = await AuthAxios.put(`/web/collaboration/user`, {
      collaboratorCode: state.collaboratorCode,
      userId: user.id,
      collaborateUserId: state.collaborateUserId,
    }).catch((error) => {
      console.error(error);
    });

    setState({
      ...state,
      existingUser: response ? response.data.existingUser : false,
    });
  };

  const autoUseFreeCoupon = async (user: User) => {
    if (!state.collaboratorCode) {
      return;
    }

    console.log('autoUseCoupon called');

    const response = await AuthAxios.post<
      any,
      AxiosResponse<{
        error?: boolean;
        message?: string;
        autoUsed?: boolean;
        couponId?: string;
        couponCode?: string;
        couponName?: string;
      }>
    >(`/web/collaboration/coupon/auto-use-free`, {
      collaboratorCode: state.collaboratorCode,
      userId: user.id,
    }).catch((error: AxiosError<{ message: string }>) => {
      console.log(error.response?.data?.message);
    });

    return response && response.data;
  };

  const autoUseRegisteredOrderedCoupon = async (user: User) => {
    console.log('users: ', user);
    console.log('state: ', state);
    console.log('auto use called');
    if (!state.collaboratorId || !state.collaborateUserId) {
      return;
    }

    if (isNaN(parseInt(state.collaboratorId))) {
      return;
    }

    console.log('autoUseRegisteredOrderedCoupon called');
    const response = await AuthAxios.put(`/web/collaboration/coupon/auto-use`, {
      collaboratorId: parseInt(state.collaboratorId),
      userId: user.id,
      collaborateUserId: state.collaborateUserId,
      orderNumber: state.orderNumber,
    }).catch((error) => {
      console.log(error);
    });

    if (response && response.data) {
      setState({
        ...state,
        couponAutoUsed: response.data.autoUsed,
        usedCouponId: response.data.couponId,
        usedCouponCode: response.data.couponCode,
        usedCouponName: response.data.couponName,
        error: response.data.error,
        message: response.data.message,
      });
    }

    // setState({
    //   ...state,
    //   couponAutoUsed: response. autoUsed,
    //   usedCouponId: response.couponId,
    //   usedCouponCode: response.couponCode
    // })

    console.log(response);
  };

  return (
    <CollaborationContext.Provider
      value={{
        state,
        setState,
        createCollaborateUser,
        linkUserToCollaborateUser,
        autoUseRegisteredOrderedCoupon,
        autoUseFreeCoupon,
      }}
    >
      {children}
    </CollaborationContext.Provider>
  );
};
