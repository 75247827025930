import React from 'react';

interface SurveyStepperProps {
  currentStep: number;
  totalSteps: number;
}

const SurveyStepper: React.FC<SurveyStepperProps> = ({ currentStep, totalSteps }) => {
  return (
    <div className="relative w-full h-2  rounded mx-auto">
      <div className="absolute top-0 left-0 w-full h-full flex justify-between">
        {Array.from({ length: totalSteps }, (_, i) => (
          <div
            key={i}
            className={`w-0.5 h-0.5  ${i === currentStep - 1 ? 'bg-N11' : 'bg-N11 bg-opacity-20'}`}
            style={{ width: `${(currentStep / totalSteps) * 100}%` }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default SurveyStepper;
