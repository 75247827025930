import {
  AngrySVG,
  CryingSVG,
  DisappointedSVG,
  FaceWithoutMouthSVG,
  HappySVG,
  SleepingSVG,
  SlightlySVG,
  ThermometerSVG,
} from '../assets';
import { CategoryFilter } from '../types';

export const emotions = [
  {
    value: {
      ko: '행복',
      en: 'Happy',
      es: '스페인어',
      de: '독일어',
      fr: '프랑스어',
      pt: '포르투갈어',
      ru: '러시아어',
      ja: '일본어',
      zh: '중국어',
    },
    svg: HappySVG,
  },
  {
    value: {
      ko: '평온',
      en: 'Calm',
      es: '스페인어',
      de: '독일어',
      fr: '프랑스어',
      pt: '포르투갈어',
      ru: '러시아어',
      ja: '일본어',
      zh: '중국어',
    },
    svg: SlightlySVG,
  },
  {
    value: {
      ko: '모름',
      en: 'Unsure',
      es: '스페인어',
      de: '독일어',
      fr: '프랑스어',
      pt: '포르투갈어',
      ru: '러시아어',
      ja: '일본어',
      zh: '중국어',
    },
    svg: FaceWithoutMouthSVG,
  },
  {
    value: {
      ko: '피곤함',
      en: 'Tired',
      es: '스페인어',
      de: '독일어',
      fr: '프랑스어',
      pt: '포르투갈어',
      ru: '러시아어',
      ja: '일본어',
      zh: '중국어',
    },
    svg: SleepingSVG,
  },
  {
    value: {
      ko: '우울함',
      en: 'Depressed',
      es: '스페인어',
      de: '독일어',
      fr: '프랑스어',
      pt: '포르투갈어',
      ru: '러시아어',
      ja: '일본어',
      zh: '중국어',
    },
    svg: DisappointedSVG,
  },
  {
    value: {
      ko: '슬픔',
      en: 'Sad',
      es: '스페인어',
      de: '독일어',
      fr: '프랑스어',
      pt: '포르투갈어',
      ru: '러시아어',
      ja: '일본어',
      zh: '중국어',
    },
    svg: CryingSVG,
  },
  {
    value: {
      ko: '화남',
      en: 'Angry',
      es: '스페인어',
      de: '독일어',
      fr: '프랑스어',
      pt: '포르투갈어',
      ru: '러시아어',
      ja: '일본어',
      zh: '중국어',
    },
    svg: AngrySVG,
  },
  {
    value: {
      ko: '아픔',
      en: 'Sick',
      es: '스페인어',
      de: '독일어',
      fr: '프랑스어',
      pt: '포르투갈어',
      ru: '러시아어',
      ja: '일본어',
      zh: '중국어',
    },
    svg: ThermometerSVG,
  },
];

export const miracleCategoryList: CategoryFilter[] = [
  {
    value: {
      ko: '전체',
      en: 'All',
      es: '스페인어',
      de: '독일어',
      fr: '프랑스어',
      pt: '포르투갈어',
      ru: '러시아어',
      ja: '일본어',
      zh: '중국어',
    },
    code: 0,
  },
  {
    value: {
      ko: '1분',
      en: '1min',
      es: '스페인어',
      de: '독일어',
      fr: '프랑스어',
      pt: '포르투갈어',
      ru: '러시아어',
      ja: '일본어',
      zh: '중국어',
    },
    code: 1,
  },
  {
    value: {
      ko: '5분',
      en: '5min',
      es: '스페인어',
      de: '독일어',
      fr: '프랑스어',
      pt: '포르투갈어',
      ru: '러시아어',
      ja: '일본어',
      zh: '중국어',
    },
    code: 5,
  },
  {
    value: {
      ko: '10분',
      en: '10min',
      es: '스페인어',
      de: '독일어',
      fr: '프랑스어',
      pt: '포르투갈어',
      ru: '러시아어',
      ja: '일본어',
      zh: '중국어',
    },
    code: 10,
  },
  {
    value: {
      ko: '20분',
      en: '20min',
      es: '스페인어',
      de: '독일어',
      fr: '프랑스어',
      pt: '포르투갈어',
      ru: '러시아어',
      ja: '일본어',
      zh: '중국어',
    },
    code: 20,
  },
  {
    value: {
      ko: '30분',
      en: '30min',
      es: '스페인어',
      de: '독일어',
      fr: '프랑스어',
      pt: '포르투갈어',
      ru: '러시아어',
      ja: '일본어',
      zh: '중국어',
    },
    code: 30,
  },
  {
    value: {
      ko: '30분 초과',
      en: '+30min',
      es: '스페인어',
      de: '독일어',
      fr: '프랑스어',
      pt: '포르투갈어',
      ru: '러시아어',
      ja: '일본어',
      zh: '중국어',
    },
    code: 31,
  },
];
