import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function ExploreBtn() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleNavHome = () => {
    navigate('/app/home');
  };
  return (
    <button onClick={handleNavHome} className="w-fit px-2 h-[40px] rounded-full bg-N5 text-N8 text-base font-medium">
      {t('web.explore')}
    </button>
  );
}
