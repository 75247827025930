import { Album } from '../../types';
import FallBackImg from '../../assets/images/fallback.jpg';

interface ICustomAlbumCompProps extends React.ComponentPropsWithRef<'button'> {
  className: string;
  album: Album;
}
export function CustomAlbumComp(props: ICustomAlbumCompProps) {
  const { className, album } = props;
  const sound_list_length = album.thumbnailList.length >= 4 ? true : false;
  if (sound_list_length) {
    return (
      <button {...props} className={`grid grid-cols-2 grid-rows-2 overflow-hidden ${className}`}>
        <img
          src={`${process.env.REACT_APP_IMG_ENDPOINT}/${album.thumbnailList[0]}`}
          alt="album-thumb-nail_1"
          className="object-cover w-full "
          onError={(event) => {
            event.currentTarget.src = FallBackImg;
          }}
          loading="lazy"
        />
        <img
          src={`${process.env.REACT_APP_IMG_ENDPOINT}/${album.thumbnailList[1]}`}
          alt="album-thumb-nail_2"
          className="object-cover w-full "
          onError={(event) => {
            event.currentTarget.src = FallBackImg;
          }}
          loading="lazy"
        />
        <img
          src={`${process.env.REACT_APP_IMG_ENDPOINT}/${album.thumbnailList[2]}`}
          alt="album-thumb-nail_3"
          className="object-cover w-full h-full "
          onError={(event) => {
            event.currentTarget.src = FallBackImg;
          }}
          loading="lazy"
        />
        <img
          src={`${process.env.REACT_APP_IMG_ENDPOINT}/${album.thumbnailList[3]}`}
          alt="album-thumb-nail_4"
          className="object-cover w-full h-full "
          onError={(event) => {
            event.currentTarget.src = FallBackImg;
          }}
          loading="lazy"
        />
      </button>
    );
  } else {
    return (
      <button {...props} className={`overflow-hidden ${className}`}>
        <img
          src={`${process.env.REACT_APP_IMG_ENDPOINT}/${album.thumbnailList[0]}`}
          alt="album-thumb-nail"
          className="object-cover w-full h-full "
          onError={(event) => {
            event.currentTarget.src = FallBackImg;
          }}
          loading="lazy"
        />
      </button>
    );
  }
}
