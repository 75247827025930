import { lazy, Suspense, useContext, useState } from 'react';
import { Route, Routes, Navigate as NavigateRoute, useNavigate, useLocation } from 'react-router-dom';
import { BgBtnSvg, LOGOSVG, MenuMobileSVG } from '../assets';

import IconButton from '../components/IconButton';
import MobileNavigate from '../components/nav/MobileNav';
import Navigate from '../components/Navigate';
import { MusicPlayerStateContext } from '../contexts/MusicContext';
import { EDITOR_Z_IDX } from '../utils/constant';
import loginImg from '../assets/images/unsplash_KonWFWUaAuk.jpg';

import OpenAppBtn from '../components/common/OpenAppBtn';
import OnePageCategoryRoot from '../pages/OnePageCategory/Root';
import OnePageAudioPlayer from '../components/audioPlayerForOnePage/Player.v2';

export default function CategoryRoot() {
  const navigate = useNavigate();
  const { currentPlayMusic, currentTheme } = useContext(MusicPlayerStateContext);
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const onClickMenu = () => {
    setMenuOpen(false);
  };
  // const noHeader = location.pathname.startsWith("/app/setting") || location.pathname.startsWith("/app/membership");
  const noHeader = location.pathname.startsWith('/app/membership');
  return (
    <div className="relative flex w-full h-full items-center justify-center">
      <img
        src={currentTheme?.artwork ? `${process.env.REACT_APP_IMG_ENDPOINT}/${currentTheme.artwork}` : loginImg}
        style={{ zIndex: -1 }}
        alt="background_theme"
        className="absolute top-0 left-0 object-cover w-full h-full"
      />
      {/* <div className="absolute top-0 left-0  bg-black bg-opacity-40 w-full h-full"></div> */}
      {/* <Navigate /> */}

      <main className="relative w-full h-full md:w-[calc(100%-240px)] overflow-hidden">
        {!noHeader && (
          <header className="px-4 flex justify-between items-center top-0 w-full h-[60px] gap-2 md:hidden ">
            <div className="flex items-center gap-2">
              <LOGOSVG className="w-[93px]" style={{ fill: 'white' }} />
            </div>
          </header>
        )}
        <main
          className={`${
            noHeader
              ? 'h-full md:h-[calc(100%-80px)] overscroll-y-auto no-scrollbar'
              : currentPlayMusic
                ? 'h-[calc(100%-140px)] md:h-[calc(100%-80px)] overscroll-y-auto no-scrollbar'
                : 'h-[calc(100%-60px)] md:h-full overscroll-y-auto no-scrollbar '
          }`}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/:id/*" element={<OnePageCategoryRoot />} />
              <Route path="*" element={<NavigateRoute to="/sleep" replace />} />
            </Routes>
          </Suspense>
        </main>
        <OnePageAudioPlayer />
      </main>

      {/* {menuOpen ? (
        <aside
          className="fixed w-screen h-full transition-all duration-500 ease-in-out transform translate-x-0 opacity-100 bg-slate-400"
          style={{ zIndex: EDITOR_Z_IDX }}
        >
          <MobileNavigate onClickMenu={onClickMenu} />
        </aside>
      ) : (
        <aside
          className="fixed w-screen h-full transition-all duration-500 ease-in-out transform translate-x-full opacity-0 bg-slate-400"
          style={{ zIndex: EDITOR_Z_IDX }}
        >
          <MobileNavigate onClickMenu={onClickMenu} />
        </aside>
      )} */}
    </div>
  );
}
