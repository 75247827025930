import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CancelSVG } from '../../assets';
import { ModalContext } from '../../contexts/ModalContext';
import IconButton from '../IconButton';
import TimePicker from '../utils/TimePicker';

interface ITimerModalProps {
  handleSetTimer: (value: number) => void;
}
export default function TimerModal(props: ITimerModalProps) {
  const { handleSetTimer } = props;
  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);
  const [hourIndex, setHourIndex] = useState(12);
  const [minuteIndex, setMinuteIndex] = useState(59);
  const handleSubmit = () => {
    let convertSecond = hourIndex * 3600 + minuteIndex * 60;
    handleSetTimer(convertSecond);

    handleModal();
  };
  return (
    <div className="text-white rounded-lg relative flex flex-col items-center w-[270px]  h-auto p-4 bg-N10 md:bg-transparent ">
      <IconButton className="absolute top-2 right-2" onClick={() => handleModal()}>
        <CancelSVG />
      </IconButton>
      <header>{t('timerModal.title')}</header>
      <div>
        <div className="relative flex flex-col items-center justify-center w-full h-auto">
          <TimePicker setHourIndex={setHourIndex} setMinuteIndex={setMinuteIndex} />
          <button
            // type="submit"
            onClick={handleSubmit}
            className="font-semibold w-[250px] h-[65px] max-sm:w-[143px] max-sm:h-[43px] rounded-lg bg-gradient-to-r from-[#63ACED] to-[#2E239D]"
          >
            {t('timerModal.setTimer')}
          </button>
          <label htmlFor="calm_down_sound" className="flex gap-2 mt-4">
            <input type="checkbox" id="calm_down_sound" />
            <span className="text-sm text-N0">{t('timer.volumeFadeOut')}</span>
          </label>
        </div>
      </div>
    </div>
  );
}
