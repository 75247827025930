import { useContext } from 'react';
import { isIOS, isAndroid, isMobile } from 'react-device-detect';
import { ModalContext } from '../../contexts/ModalContext';
import QRCODE from '../../assets/images/qrcode.png';
import IconButton from '../IconButton';
import { CancelSVG } from '../../assets';
import { useTranslation } from 'react-i18next';

export default function OpenAppBtn() {
  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);
  const onClickOpenApp = () => {
    if (isMobile) {
      exeDeepLink();
    } else {
      handleModal(<QRCodeComponent />);
    }
  };

  const exeDeepLink = () => {
    if (isIOS) {
      //   let url = "yourapp://path/";
      //   window.location.href = url;
      setTimeout(() => redirectStore(), 1000);
    } else if (isAndroid) {
      //   let url = "yourapp://path/";
      //   window.location.href = url;
      setTimeout(() => redirectStore(), 1000);
    }
  };
  const redirectStore = () => {
    // Check if the user is on iOS or Android
    if (isIOS) {
      // Replace 'your-ios-app-id' with your app's App Store ID
      window.location.href = `https://apps.apple.com/kr/app/%EC%82%AC%EC%9A%B4%EB%93%9C%ED%95%84/id6450494947`;
    } else if (isAndroid) {
      // Replace 'your-package-name' with your app's package name
      window.location.href = `https://play.google.com/store/apps/details?id=info.digitalnutrition.soundpill2&hl=ko-KR`;
    }
  };

  return (
    <button
      onClick={onClickOpenApp}
      className="w-fit px-2 h-[40px] rounded-full bg-N8 text-[#F4F4F4] font-medium text-base"
    >
      {t('web.openApp')}
    </button>
  );
}

function QRCodeComponent() {
  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);
  return (
    <div className="flex flex-col p-4 rounded-lg bg-N10 text-N0 w-[280px]">
      <div className="flex justify-end w-full mb-2">
        <IconButton onClick={() => handleModal()}>
          <CancelSVG />
        </IconButton>
      </div>
      <p className="text-center ">{t('qrCodePopup.scanQR')}</p>
      <div className="flex justify-center">
        <img src={QRCODE} className="w-[150px] h-[150px] object-cover" />
      </div>
    </div>
  );
}
