import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import { AddFolderSVG, ArrowLeftSVG, OutLineMenuSVG, PlayingMusicSVG, TrashSVG } from '../../assets';
import { MusicPlayerStateContext } from '../../contexts/MusicContext';
import { Track } from '../../types';
import { EDITOR_Z_IDX } from '../../utils/constant';
import formatDurationDisplay from '../../utils/formatDurationDisplay';
import IconButton from '../../components/IconButton';
import { ModalContext } from '../../contexts/ModalContext';
import { transLn } from '../../utils/transLn';
import AlbumModal from '../../components/modals/AlbumModal';
import FallBackImg from '../../assets/images/fallback.jpg';
import AuthAxios from '../../axios';
import { useTranslation } from 'react-i18next';

export default function PlayListPage() {
  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);
  const {
    playList: tracks,
    setPlayList: setTracks,
    onChangePlayListOrder,
    currentPlayMusic,
  } = useContext(MusicPlayerStateContext);
  const navigate = useNavigate();

  const [selectTrackList, setSelectTrackList] = useState<Track[]>([]);
  const [edit, setEdit] = useState(false);

  const [currentTracks, setCurrentTracks] = useState<Track[]>([]);
  useEffect(() => {
    console.log(
      'tracks: ',
      tracks.map((t) => t.title)
    );
    setCurrentTracks([...tracks]);
  }, [tracks]);

  const selectAll = () => {
    setSelectTrackList(tracks);
  };

  const deselectAll = () => {
    setSelectTrackList([]);
  };

  const handleEditChange = () => {
    setEdit((prev) => !prev);
    setSelectTrackList([]);
  };

  const handleClickTrack = (track: Track) => {
    const isSelected = selectTrackList.some((item) => item.id === track.id);

    if (isSelected) {
      // 이미 선택되었다면.
      setSelectTrackList(selectTrackList.filter((item) => item.id !== track.id));
    } else {
      // 새로 선택되었다면.
      setSelectTrackList((prev) => [...prev, track]);
    }
  };

  const handleDeleteOnPlayList = () => {
    const newPlayList = tracks.filter((track) => {
      return !selectTrackList.find((selectTrack) => selectTrack.id === track.id);
    });

    AuthAxios.post(`/web/playlist/delete/multiple`, {
      trackIds: selectTrackList.map((item) => item.id),
    })
      .then(() => {
        setTracks(newPlayList);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSelectTrackList([]);
      });
  };

  const handleOpenAlbumModal = () => {
    handleModal(<AlbumModal selectedTrackList={selectTrackList} />);
  };

  const handleUpdatePlayList = (updated: Track[]) => {
    const prevOrder = tracks.map((t) => t.id).join(',');
    const nextOrder = updated.map((t) => t.id).join(',');
    if (prevOrder === nextOrder) return;

    setCurrentTracks(updated);
    onChangePlayListOrder(updated);
  };

  return (
    <>
      {currentPlayMusic && (
        <img
          src={`${process.env.REACT_APP_IMG_ENDPOINT}/${currentPlayMusic.artwork}`}
          style={{ zIndex: -1 }}
          alt="background_artwork"
          className="absolute left-0 object-cover w-screen h-screen -top-0"
        />
      )}
      <div className="relative flex flex-col w-full h-full text-white bg-N10/70 md:px-20 md:pt-12">
        <div className="justify-between px-2 mt-2 md:flex">
          {!edit ? (
            <div className="flex items-center justify-between w-full gap-2 mb-[22px]">
              <IconButton
                className="flex items-center"
                size="sm"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowLeftSVG />
                <span>{t('playlist.currentPlaylist')}</span>
              </IconButton>

              <IconButton
                className=" text-base w-[58px] h-[30px] bg-N7 text-N1 rounded-full drop-shadow-lg"
                onClick={handleEditChange}
              >
                {t('playlist.edit')}
              </IconButton>
            </div>
          ) : // <div>{selectTrackList.length}곡 선택</div>
          null}
        </div>
        {edit && (
          <div className="flex justify-between w-full gap-2 px-2 mb-[22px]">
            <div className="flex gap-4 items-center">
              <div className="flex gap-2 items-center">
                {selectTrackList.length}
                {t('search.selectTracks')}
              </div>

              <button onClick={selectAll}>{t('playlist.selectAll')}</button>
              <button onClick={deselectAll}>{t('playlist.deselectAll')}</button>
            </div>
            <div className="flex gap-2">
              {/* <button className="" onClick={() => setEdit(false)}>
                {t("playlist.cancel")}
              </button> */}
              <button
                className="w-fit px-2 h-[30px] rounded-full bg-N9 text-N3 text-base drop-shadow-lg"
                onClick={() => setEdit(false)}
              >
                {t('playlist.confirm')}
              </button>
            </div>
          </div>
        )}
        <ReactSortable
          className="flex flex-col w-full h-full px-2 overflow-y-scroll no-scrollbar"
          handle=".my-handle"
          animation={100}
          list={tracks}
          setList={handleUpdatePlayList}
        >
          {currentTracks.map((track, index) => (
            <TrackItems
              key={`playlist-track-${track.id}`}
              edit={edit}
              track={track}
              selectTrackList={selectTrackList}
              handleClickTrack={handleClickTrack}
            />
          ))}
        </ReactSortable>
        {edit && (
          <div
            style={{ zIndex: EDITOR_Z_IDX, margin: 'auto' }}
            className="fixed md:absolute bottom-0 left-0 right-0 mx-auto w-full md:w-[512px] h-20 md:h-[48px] text-white bg-N9 rounded-tr-[8px] rounded-tl-[8px] p-2 flex justify-around"
          >
            <IconButton onClick={handleOpenAlbumModal}>
              <AddFolderSVG />
            </IconButton>
            <IconButton onClick={() => handleDeleteOnPlayList()}>
              <TrashSVG />
            </IconButton>
          </div>
        )}
      </div>
    </>
  );
}
function TrackItems({
  edit,
  track,
  selectTrackList,
  handleClickTrack,
}: {
  edit: boolean;
  track: Track;
  selectTrackList: Track[];
  handleClickTrack: (value: Track) => void;
}) {
  const { onChangeMusic, currentPlayMusic } = useContext(MusicPlayerStateContext);
  const [selected, setSelected] = useState(selectTrackList.some((selectTrack) => selectTrack.id === track.id));

  const onClickItem = () => {
    if (edit) {
      handleClickTrack(track);
      setSelected((prev) => !prev);
    } else {
      onChangeMusic(track);
    }
  };
  useEffect(() => {
    setSelected(selectTrackList.some((selectTrack) => selectTrack.id === track.id));
  }, [selectTrackList]);

  useEffect(() => {
    if (!edit) setSelected(false);
  }, [edit]);

  return (
    <div className={`flex items-center justify-between py-2 ${selected ? ' bg-slate-400/30' : ' bg-transparent'}`}>
      <div className="flex w-full gap-2 cursor-pointer" onClick={onClickItem}>
        <img
          src={`${process.env.REACT_APP_IMG_ENDPOINT}/${track.thumbnail}`}
          className="object-cover rounded-lg min-w-[64px] h-14"
          alt="playlist-thumbnail"
          onError={(event) => {
            event.currentTarget.src = FallBackImg;
          }}
        />
        <div className="mb-1 text-left max-sm:text-left">
          <p className="font-bold text-slate-300">{transLn(track.title) ?? 'Select a song'}</p>
          <p className="text-xs">{transLn(track.category)}</p>
        </div>
      </div>
      <div className="flex items-center gap-3 justify-self-center">
        {currentPlayMusic?.id === track.id ? (
          <PlayingMusicSVG />
        ) : (
          <span className="text-xs">{formatDurationDisplay(track.duration)}</span>
        )}
        <IconButton size="lg" className="my-handle">
          <OutLineMenuSVG />
        </IconButton>
      </div>
    </div>
  );
}
