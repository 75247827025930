import { FormEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { SOUNDPILLICONSVG } from '../../assets';
import { PaymentMethod } from '../../types';
import AuthAxios from '../../axios';
import IconButton from '../../components/IconButton';
import APP from '../../components/paypal';
import { AuthStateContext } from '../../contexts/AuthContext';
import { ModalContext } from '../../contexts/ModalContext';
import { Product, ResponseType } from '../../types';
import { transLn } from '../../utils/transLn';
import { v4 as uuidv4 } from 'uuid';
import { formatDateToYYYYMMDD, formatDate } from '../../utils/formatDateDisplay';
import { FailRegistCard, SuccessRegistCard } from '../../components/modals/RegistCardModal';
//import * as PortOne from "@portone/browser-sdk/v2";
//import {PortOne} from @portone/browser-sdk
import * as PortOne from '@portone/browser-sdk/v2';

const { IMP } = window;
//IMP.init(process.env.REACT_APP_IMP_CODE);

interface DiscoundCoupon {
  id: number;
  name: string;
  type: string;
  value: number;
  status: string;
}

interface Payment {
  totalDiscount: number;
  totalPrice: number;
  duration: number;
  totalPayment: number;
}

declare const window: typeof globalThis & {
  IMP: any;
};

export default function PaymentPage() {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const { t } = useTranslation();
  const location = useLocation();
  const { state } = location;
  // console.log("state::", state);
  const product = state?.product as Product;
  const navigate = useNavigate();
  const { handleModal } = useContext(ModalContext);
  const { user } = useContext(AuthStateContext);
  const { handleSubscribtion } = useContext(AuthStateContext);

  const [couponList, setCouponList] = useState<DiscoundCoupon[]>([]);
  const [currentCoupon, setCurrentCoupon] = useState<DiscoundCoupon | null>(null);
  const [payment, setPayment] = useState<Payment>();
  const [isCard, setIsCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paypal, setPaypal] = useState(false);
  const [paypalPrice, setPaypalPrice] = useState(6.5);

  const onClickIcon = () => {
    navigate('/app/home', { replace: true });
  };

  const onChangeCoupon = (value: number) => {
    if (value) {
      setCurrentCoupon(couponList.find((item) => item.id === value)!);
      AuthAxios.get<Payment>(`/web/payment/price/?couponId=${value}&productId=${product.id}`)
        .then((resolve) => {
          // console.log(resolve.data);
          setPayment(resolve.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setCurrentCoupon(null);
      AuthAxios.get<Payment>(`/web/payment/price/?productId=${product.id}`)
        .then((resolve) => {
          // console.log(resolve.data);
          setPayment(resolve.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handlePostPayment = (rsp: any) => {
    setLoading(true);
    AuthAxios.post(`/web/payment`, {
      currency: 'KRW',
      orderProductId: product.id,
      couponId: currentCoupon?.id,
      impUid: rsp.imp_uid,
      status: rsp.status,
      amount: rsp.paid_amount,
    })
      .then((resolve) => {
        // console.log(resolve);
        handleSubscribtion();
        navigate('/app/home', { replace: true });
        //navigate("/app/setting/subscribtion");
        handleModal(<SuccessPaymentModal />);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRegistCard = () => {
    const { IMP } = window;
    IMP.init(process.env.REACT_APP_IMP_CODE);
    const customerUID = uuidv4();
    IMP.request_pay(
      {
        pg: 'danal_tpay.A010014468', // 실제 계약 후에는 실제 상점아이디로 변경
        pay_method: 'card', // 'card'만 지원됩니다.
        amount: 0, // 결제창에 표시될 금액. 실제 승인이 이루어지지는 않습니다. (모바일에서는 가격이 표시되지 않음)
        customer_uid: customerUID, // 필수 입력.
        name: '최초인증결제',
        merchant_uid: 'order_monthly_' + customerUID, // 상점에서 관리하는 주문 번호
      },
      function (rsp: any) {
        console.log('카드 등록 결과 rsp::', rsp);
        if (rsp.success) {
          // 상품 구매 성공.
          console.log('다날 카드 등록까지는 성공함.');

          // 카드 등록 api.
          AuthAxios.put(`/web/paymentMethod/card2`, {
            customerUid: customerUID,
          })
            .then((resolve) => {
              console.log('카드 등록 성공');
              setIsCard(true);
              handleModal(<SuccessRegistCard successMessage={rsp.card_name ?? ''} />);
            })
            .catch((error) => {
              console.log('카드 등록 실패');
              handleModal(<FailRegistCard errorMessage={error.response.data.message ?? ''} />);
            });
        } else {
          // 카드 등록 실패.
          console.log('카드 등록 실패.');
          handleModal(<FailRegistCard errorMessage={rsp.error_msg ?? ''} />);
        }
      }
    );
  };

  const onClickpostPaypalPayment = async (product: Product) => {
    console.log('onClickpostPaypalPayment');
    setPaypal(true);
    console.log('Product', product.price);
    setPaypalPrice(product.price);
    const customerUID = uuidv4();
    // IMP.init(process.env.REACT_APP_IMP_CODE);

    // IMP.loadUI(
    //   "paypal-rt",
    //   {
    //     pg: "paypal_v2.LU2S3GXCDJ6TJ", // 실제 계약 후에는 실제 상점아이디로 변경
    //     pay_method: "paypal", // 'card'만 지원됩니다.
    //     amount: 1000, // 결제창에 표시될 금액. 실제 승인이 이루어지지는 않습니다. (모바일에서는 가격이 표시되지 않음)
    //     customer_uid: customerUID, // 필수 입력.
    //     name: "페이팔 결제 수단 등록",
    //     merchant_uid: "order_monthly_" + customerUID, // 상점에서 관리하는 주문 번호
    //     // buyer_email: "iamport@siot.do",
    //     // buyer_name: "아임포트",
    //     // buyer_tel: "02-1234-1234",
    //     // m_redirect_url: process.env.REACT_APP_IMP_M_REDIRECT_URL, // 예: https://www.my-service.com/payments/complete/mobile
    //   },
    //   function (rsp: any) {
    //     console.log("rsp", rsp)
    //     if (rsp.success) {
    //       console.log("rsp.success", rsp.success)
    //     } else {
    //     }
    //   }
    // );

    // const requestData: any = {
    //   uiType: "PAYPAL_RT",
    //   storeId: "store-f32d9283-de9c-4434-8057-c2206723cbea",
    //   channelKey: "channel-key-814e03c1-f253-49ac-a1d1-9b580aab278a",
    //   issueId: `issue-id-${crypto.randomUUID()}`,
    // };

    // const response = await PortOne.loadIssueBillingKeyUI(requestData, {
    //   onIssueBillingKeySuccess: (response) => {
    //     console.log("response", response)
    //     //setResult(response);
    //   },
    //   onIssueBillingKeyFail: (error) => {
    //     console.log("error", error)
    //     //alert(error);
    //   },
    // });
  };

  const onClickpostPayment = () => {
    if (false) {
      // handleModal(<CheckPasswordModal callback={handlePostPayment} />);
      // handlePostPayment();
    } else {
      const customerUID = uuidv4();
      const { IMP } = window;
      IMP.init(process.env.REACT_APP_IMP_CODE);
      console.log('onClickpostPayment', product.duration);

      let now = new Date();
      IMP.request_pay(
        {
          pg: 'danal_tpay.A010014468', // 실제 계약 후에는 실제 상점아이디로 변경
          pay_method: 'card', // 'card'만 지원됩니다.
          amount: product.price, // 결제창에 표시될 금액. 실제 승인이 이루어지지는 않습니다. (모바일에서는 가격이 표시되지 않음)
          customer_uid: customerUID, // 필수 입력.
          name: transLn(product.description),
          period: {
            from: formatDate(new Date(now)), //YYYYMMDD
            to: formatDate(new Date(now.setDate(now.getDate() + product.duration))), //YYYYMMDD now.setMonth(now.getMonth() + 1))  date.getDate() - 1)
          },
          merchant_uid: 'order_monthly_' + customerUID, // 상점에서 관리하는 주문 번호
          // buyer_email: "iamport@siot.do",
          // buyer_name: "아임포트",
          // buyer_tel: "02-1234-1234",
          // m_redirect_url: process.env.REACT_APP_IMP_M_REDIRECT_URL, // 예: https://www.my-service.com/payments/complete/mobile
        },
        function (rsp: any) {
          // callback
          console.log('aa', rsp);
          if (rsp.success) {
            console.log('response: ', rsp);

            handlePostPayment(rsp);

            AuthAxios.put(`/web/paymentMethod/card2`, {
              customerUid: customerUID,
            })
              .then((resolve) => {
                console.log('카드 등록 성공');
                setPaymentMethod({
                  isCard: true,
                  cardName: rsp.card_name,
                  customerUid: customerUID,
                });
                //navigate("/app/setting/subscribtion");
              })
              .catch((error) => {
                console.log('카드등록 실패');
              });
          } else {
            // 빌링키 발급 실패
          }
        }
      );
    }
  };

  useEffect(() => {
    if (product) {
      // 현재 카드가 등록되어 있는지 확인 필요.
      AuthAxios.get(`/web/paymentMethod/card`)
        .then((resolve) => {
          setIsCard(resolve.data.isCard);
        })
        .catch((error) => {
          console.log(error);
        });
      //현재 상품이 뭔지 알고 .
      // 쿠폰리스트 받아오고.
      // 초기 적용된 결제 결과 가져오고.
      AuthAxios.get<ResponseType<DiscoundCoupon>>(`web/coupon/list/available?type=discount`)
        .then((resolve) => {
          setCouponList(resolve.data.list);
        })
        .catch((error) => {
          console.log(error);
        });
      AuthAxios.get<Payment>(`/web/payment/price/?productId=${product.id}`)
        .then((resolve) => {
          // console.log(resolve.data);
          setPayment(resolve.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  if (!product) {
    return <div>not fount product</div>;
  }
  return (
    <div className="flex flex-col w-full h-full bg-N0 px-6 py-4 md:py-[27px] md:px-[42px] overflow-auto max-md:no-scrollbar">
      <header>
        <IconButton onClick={onClickIcon}>
          <SOUNDPILLICONSVG />
        </IconButton>
      </header>
      <div className="flex justify-center w-full h-full ">
        <div className="flex flex-col items-center w-full md:w-[555px] h-full">
          <h1 className="text-[22px] font-medium mb-[21px] md:mb-[44px]">
            <strong>SoundPill</strong> {t('paymentMembershipPage.becomePremiumMember')}
          </h1>
          {/* <div className="flex justify-between w-full md:items-center max-md:flex-col max-md:gap-[6px]">
            <span className="w-[72px] text-base">
              {t("paymentMembershipPage.discountCoupon")}
            </span>
            <select
              className="w-full h-[50px] rounded-lg bg-N8 text-N0 px-6 py-3 text-base font-medium"
              onChange={(e) => {
                onChangeCoupon(Number(e.currentTarget.value));
              }}
            >
              {couponList.map((coupon, idx) => {
                return (
                  <option key={`discound-coupon-${idx}`} value={coupon.id}>
                    {coupon.name}
                    {coupon.value}%
                  </option>
                );
              })}
              <option value={0}>{t("paymentMembershipPage.selectNone")}</option>
            </select>
          </div> */}

          <div className="flex flex-col items-center w-full my-[18px] md:my-[30px]">
            <p>{t('paymentMembershipPage.accountWhereBenefitsApply')}</p>
            <p className="underline text-N6">{user?.email}</p>
          </div>
          <div className="flex flex-col items-start w-full gap-[10px] md:gap-4">
            <div>{t('paymentMembershipPage.orderSummary')}</div>
            <div className="flex justify-between w-full">
              <span>{transLn(product.description)}</span>
              <span>₩{(product.price || 0).toLocaleString()}</span>
            </div>
            {currentCoupon ? (
              <div className="flex justify-between w-full">
                <span>
                  {currentCoupon.name} {currentCoupon.value}%
                </span>
                <span>- ₩{((payment?.totalPrice || 0) - (payment?.totalPayment || 0)).toLocaleString()}</span>
              </div>
            ) : null}

            <div className="flex justify-between w-full py-4 border-t border-b border-N10">
              <span>
                <strong>{t('paymentMembershipPage.totalAfterDiscount')}</strong>
              </span>
              <span>₩{payment?.totalPayment}</span>
            </div>

            <div className="flex flex-col w-full gap-[21px] md:gap-[30px] mt-[30px] md:mt-12">
              <div id="paypal-button-container-P-7M764541P3543684BMYB33YI"></div>
              <div className="portone-ui-container" data-portone-ui-type="paypal-rt"></div>
              {paypal ? <APP></APP> : ''}
              <button
                disabled={loading}
                onClick={() => onClickpostPaypalPayment(product)}
                className="w-full rounded-lg bg-[#6D6AF6] text-N0 text-lg font-semibold py-4"
              >
                {t('giftPaymentPage.payForGiftCertificate')}
              </button>
              <button
                disabled={loading}
                onClick={onClickpostPayment}
                className="w-full rounded-lg bg-[#712DE0] text-N0 text-lg font-semibold py-4"
              >
                {t('paymentMembershipPage.payWithKoreanCard')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center text-sm text-[#8F8F8F] my-[21px] md:my-[30px]">
        <p className="mb-[18px]">{t('paymentMembershipPage.allTransactionsAreSecure')}</p>
        <p>{t('paymentMembershipPage.annualPremiumMembership')}</p>
        {/* <p>{t("paymentMembershipPage.yearlySubscriptionPaymentAmount")}</p> */}
        <p>{t('paymentMembershipPage.ifYouWishToCancelSubscription')}</p>
      </div>
    </div>
  );
}

interface ICheckPasswordModalProps {
  callback: Function;
}
function CheckPasswordModal(props: ICheckPasswordModalProps) {
  const { callback } = props;
  const { handleModal } = useContext(ModalContext);

  const onClickSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    callback();
    handleModal();
  };
  return (
    <div className="w-[328px] rounded-lg bg-N10 text-N0 flex flex-col items-center justify-center p-4">
      <div className="text-sm">결제 후 바로 구독이 시작됩니다.</div>
      <div className="mb-4 text-sm">다음 결제는 1달 뒤 입니다.</div>
      <form onSubmit={onClickSubmit} className="w-full">
        <button type="submit" className="w-full h-12 rounded-lg bg-N7 ">
          결제하기
        </button>
      </form>
    </div>
  );
}

function SuccessPaymentModal() {
  const { handleModal } = useContext(ModalContext);

  const onClickClose = () => {
    handleModal();
  };

  return (
    <div className="w-[328px] rounded-lg bg-N10 text-N0 flex flex-col p-4 items-center">
      <div className=" text-[22px] font-bold">결제 완료</div>
      <div className="mb-4">결제가 성공적으로 완료되었습니다.</div>
      <button className="w-full h-12 rounded bg-N5 text-N9" onClick={() => onClickClose()}>
        확인
      </button>
    </div>
  );
}
