import { Language } from '../types';
import { getItem, LocalLanguage } from './localStorage';

export function transLn<T>(language: Language<T>) {
  const current = getItem(LocalLanguage) ?? 'ko';

  switch (current) {
    case 'ko':
      return language.ko;
    case 'en':
      return language.en;
    case 'es':
      return language.es;
    case 'de':
      return language.de;
    case 'fr':
      return language.fr;
    case 'pt':
      return language.pt;
    case 'ru':
      return language.ru;
    case 'ja':
      return language.ja;
    case 'zh':
      return language.zh;
    default:
      return language.ko;
  }
}
