import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LOGOSVG } from '../../assets';
import AuthAxios from '../../axios';
import { ModalContext } from '../../contexts/ModalContext';

export default function BasicInfoPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleModal } = useContext(ModalContext);
  const [selectedGender, setSelectedGender] = useState<'male' | 'female' | null>(null);
  const [nickName, setNickName] = useState<string>();
  const [birthDay, setBirthDay] = useState<string>();
  const isFormComplete = nickName && selectedGender && birthDay;

  const handlePutBasicInfo = () => {
    AuthAxios.put(`/web/users/extra-info`, {
      nickName: nickName,
      gender: selectedGender,
      birthday: birthDay,
    })
      .then((resolve) => {
        navigate('/app/home');
      })
      .catch((error) => {
        handleModal(
          <div className="w-[300] bg-N10 flex flex-col items-center rounded-[22px] text-N0 p-2 px-4">
            <div>{error.response.data.message ?? '사용중인 닉네임입니다.'}</div>
            <button
              className="w-full bg-N5 text-N10 rounded-[22px] text-base font-semibold"
              onClick={() => handleModal()}
            >
              확인
            </button>
          </div>
        );
      });
  };
  return (
    <div className="flex flex-col items-center justify-start w-full">
      <div className="flex items-center justify-between w-full px-5 mt-5 mb-[128px]">
        <LOGOSVG className="w-[93px] md:w-[163px]" />
      </div>

      <form
        className="w-[320px] h-[417px] text-white"
        onSubmit={(e) => {
          e.preventDefault();
          handlePutBasicInfo();
        }}
      >
        <div className="text-lg font-bold mb-[24px]">{t('userInfoPage.prompt')}</div>
        <div className="flex flex-col gap-2 mb-[12px]">
          <label htmlFor="nickName">{t('userInfoPage.nickname')}</label>
          <input
            onChange={(e) => setNickName(e.target.value)}
            name="nickName"
            type="text"
            placeholder={t('userInfoPage.inputNickname')}
            className="focus:outline-none w-[320px] h-[56px]  rounded-lg p-3 text-base text-black placeholder:text-N3 focus:placeholder:drop-shadow-md md:w-[360px] md:h-[56px]"
          />
        </div>
        <div className="flex flex-col gap-2 mb-[12px] w-[320px] md:w-[360px]">
          <label className="text-base">{t('userInfoPage.gender')}</label>
          <div className="flex gap-3">
            <input
              type="radio"
              id="male"
              name="gender"
              value="male"
              className="sr-only"
              onChange={() => setSelectedGender('male')}
            />
            <label
              htmlFor="male"
              className={`rounded-lg cursor-pointer w-[154px] h-[56px] md:w-[172px] bg-N2 flex justify-center items-center ${
                selectedGender === 'male' ? ' bg-N5 border border-black text-black ' : ''
              }`}
            >
              {t('userInfoPage.male')}
            </label>
            <input
              type="radio"
              id="female"
              name="gender"
              value="female"
              className="sr-only"
              onChange={() => setSelectedGender('female')}
            />
            <label
              htmlFor="female"
              className={`rounded-lg cursor-pointer w-[154px] h-[56px] md:w-[172px] bg-N2 flex justify-center items-center ${
                selectedGender === 'female' ? ' bg-N5 border border-black text-black ' : ''
              }`}
            >
              {t('userInfoPage.female')}
            </label>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-[24px]">
          <label htmlFor="birthDay">{t('userInfoPage.birthYear')}</label>
          <input
            maxLength={4}
            name="birthDay"
            type="text"
            value={birthDay}
            placeholder="2023"
            className="focus:outline-none w-[320px]  h-[56px]  rounded-lg p-3 text-base text-black placeholder:text-N3 focus:placeholder:drop-shadow-md md:w-[360px] md:h-[56px]"
            onChange={(e) => {
              const newValue = e.currentTarget.value.replace(/[^0-9]/g, '');
              setBirthDay(newValue);
            }}
          />
        </div>
        <button
          type="submit"
          disabled={!isFormComplete}
          className={`rounded-lg w-[320px] h-[56px] text-N2 leading-6 md:w-[360px] md:h-[56px] ${
            isFormComplete ? '' : 'opacity-50 cursor-not-allowed'
          }`}
          style={{
            textAlign: 'center',
            backgroundImage: 'linear-gradient(160deg, #473CBC, #202E67)',
          }}
        >
          {t('userInfoPage.submitComplete')}
        </button>
      </form>
    </div>
  );
}
