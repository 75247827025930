import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext } from '../../contexts/ModalContext';
import { Account, User } from '../../types';
import AuthAxios from '../../axios';

/**
 * DB생명에서 접속한 유저에게 쿠폰 지급 사실을 알림
 */
export function CouponNotification({
  user,
  usedCouponName,
}: {
  user: User;
  usedCouponName?: string;
  usedCouponCode?: string;
}) {
  const [account, setAccount] = useState<Account | null>(null);

  useEffect(() => {
    async function getUserDetails() {
      try {
        const { data } = await AuthAxios.get('/web/users');
        setAccount(data.subscription);
      } catch (error) {
        console.error(error);
      }
    }
    getUserDetails();
  }, []);

  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);

  return (
    <main className="flex justify-center p-2 mt-4">
      <div className="flex flex-col gap-5 p-4 w-full text-N0 bg-N8 rounded-xl sm:p-14 sm:w-[532px] drop-shadow-xl">
        <header className="">
          <p className="text-3xl font-bold mb-8">지금 추천 사운드를 재생해보세요!</p>
          <p>
            {t('subscriptionManagementPage.greeting', {
              name: account?.nickName,
            })}
            <br />
          </p>
        </header>

        {usedCouponName ? (
          <div className="p-[15px] bg-N7 rounded-xl">
            <div className="flex flex-col justify-between">
              <span>{usedCouponName} 쿠폰이 적용되었습니다.</span>
            </div>
          </div>
        ) : (
          <button className="w-full h-[56px] rounded-lg text-N1 font-semibold text-lg drop-shadow-xl bg-gradient-to-b from-[#473CBC] to-[#202E67]"></button>
        )}
        <div>
          <p>{t('subscriptionManagementPage.annualSubscription')}</p>
          <ul className="pl-6 list-disc">
            <li> {t('subscriptionManagementPage.unlimitedListening')}</li>
            <li> {t('subscriptionManagementPage.offlineDownload')}</li>
          </ul>
        </div>
        <div className="flex justify-end w-full gap-2">
          <button onClick={() => handleModal()}>확인</button>
        </div>
      </div>
    </main>
  );
}
