import React from 'react';
import DBLogo from '../../assets/DBInsurance/db_soundpill_logo.png';
import SurveyStart from '../../assets/images/survey_start.png';
import { useNavigate } from 'react-router-dom';

export const DBSurveyStart = () => {
  /** Initialization */
  const navigate = useNavigate();
  /** Handlers */
  return (
    <div className="flex flex-col justify-center w-full h-full bg-gray-400">
      <div className="flex flex-col items-center  w-full md:w-[50rem] h-full md:h-full m-auto bg-white">
        <div className="flex flex-col w-full h-[700px] md:h-full items-center overflow-y-scroll">
          <img
            src={DBLogo}
            alt="DBInsurance"
            className="w-[10.125rem] h-[2.25rem] m-10 md:m-[5rem]  ml-5 md:mr-[38%]  lg:mb-20"
          />
          <h1 className="font-bold mx-5 md:mr-[15%]  bg-[191919] text-2xl tracking-[0.2rem]">
            현재 내 건강상태에 필요한 <br /> 건강향상 사운드는 ?
          </h1>
          <img src={SurveyStart} alt="" className="w-[17.0344rem] md:w-[16rem] h-[12.25rem] md:h-[11.625rem] mt-10" />
          <div className="mt-[1.875rem] md:mr-[10%]">
            <h1 className="font-bold  mx-5 mb-8 bg-[191919] text-xl  lg:my-[2rem]">
              사운드필의 인공지능이 <br /> 나에게 맞는 건강 사운드를 제공해드려요!
            </h1>
            <span className="font-bold w-[21rem] mx-5  bg-[191919] text-base text-N11 tracking-[0.06rem]">
              10문항, 예상 소요시간 30초
            </span>
          </div>
          <button
            className="w-[300px] md:w-[50%] h-[50px] bg-N12  m-auto text-xl text-white font-bold rounded-lg"
            onClick={() => navigate('survey-questions')}
          >
            시작할래요
          </button>
        </div>
      </div>
    </div>
  );
};
