import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext } from '../../contexts/ModalContext';

interface IEditorPlayListModal {
  /** 현재 재생목록에 재생하기  */
  onClickAddPlayAll: Function;
  /** 현재 재생목록 비우고 재생하기  */
  onCLickResetPlayAll: Function;
}

/** 현재 재생목록에 재생하기
 * 
    현재 재생목록 비우고 재생하기 
    */
export default function EditorPlayListModal(props: IEditorPlayListModal) {
  const { t } = useTranslation();
  const { handleModal } = useContext(ModalContext);
  const { onClickAddPlayAll, onCLickResetPlayAll } = props;

  const onClickAdd = () => {
    onClickAddPlayAll();
    handleModal();
  };
  const onClickClear = () => {
    onCLickResetPlayAll();
    handleModal();
  };
  return (
    <div className="flex flex-col  w-[329px]  h-[112px] text-white bg-N10 justify-center">
      <button onClick={onClickAdd}>{t('category.addToCurrentPlaylist')}</button>
      <button onClick={onClickClear}>{t('category.emptyAndAddToCurrentPlaylist')}</button>
    </div>
  );
}
