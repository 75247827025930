import { useEffect, useState } from 'react';
import { getItem, LocalLanguage } from '../../utils/localStorage';

const tableContents = [
  '1. Purpose of Collecting and Using Personal Information',
  '2. Items of Personal Information Collected, Collection Methods, and Retention Period',
  '3. Handling of Personal Information of Children Under 14 Years Old',
  '4. Retention and Destruction of Personal Information',
  '5. Provision and Delegation of Personal Information to Third Parties',
  '6. Rights of Users and Legal Representatives and Their Exercise Methods',
  '7. Installation, Operation, and Rejection of Automatic Data Collection Devices',
  '8. Collection, Use, Provision, and Rejection of Behavioral Information',
  '9. Technical and Administrative Measures for Personal Information Protection',
  '10. Complaints Regarding Personal Information',
  '11. Changes to the Privacy Policy',
];

export default function PrivatePolicyPage() {
  const [langauge, setLanguage] = useState('');

  useEffect(() => {
    const lan = getItem(LocalLanguage);
    setLanguage(lan);
  });
  if (langauge === 'en') {
    return (
      <main className="w-full h-full overflow-y-auto no-scrolbar bg-N0">
        <div className="my-5 mx-[1rem]">
          <p className="mb-5">
            <strong>SoundPill Privacy Policy</strong>
          </p>
          <p className="leading-6 font-1 text-sm">
            <strong>Digital Nutrition Corp.</strong> (hereinafter referred to as the "Company") strictly adheres to the
            Personal Information Protection Act and strives to protect the personal information of users. Accordingly,
            in accordance with Article 30 of the Personal Information Protection Act, the Company establishes and
            discloses the following privacy policy to guide the procedures and standards for the processing of personal
            information and to ensure that any complaints related to the processing of personal information are handled
            quickly and efficiently. This privacy policy may be changed according to changes in relevant laws and
            regulations or internal operational policies.
          </p>

          <p className="leading-6 font-1 text-sm">
            <strong>Main Personal Information Processing Display (Labeling)</strong>
          </p>
          <p className="leading-6 font-1 text-sm">
            · General Personal Information Collection: Name, Mobile Number, Date of Birth, etc. <br /> · For detailed
            items, refer to the full text of the Privacy Policy <br /> · Purpose of Personal Information Processing:
            Membership management, service provision, service development, marketing utilization(optional), etc. <br />{' '}
            · Automatic Collection: Cookies, ADID, IDFA <br /> · Customer Service: Inquiry through Soundpill or via
            email at share@digitalnutrition.info
          </p>
          <p className="leading-6 font-1 text-sm my-2">
            <strong>Table of Contents</strong>
          </p>

          {tableContents?.map((content: string, index: number) => {
            return (
              <p className="leading-6 font-1 text-sm" key={index}>
                {content}
              </p>
            );
          })}
          <ul className="leading-6 font-1 text-sm my-2">
            <li>
              <strong>1. Purpose of Collecting and Using Personal Information</strong>
              The Company collects the minimum personal information necessary for the following purposes:
            </li>
            <li>
              · Membership Management: Providing member services and verifying identity (including verifying legal
              guardian consent for users under 14 years old). <br /> · Contract Fulfillment and Service Provision:
              Providing content, processing payments, shipping goods, and handling inquiries. <br /> · Service
              Development and Marketing: Recommending personalized content based on user interests and preferences,
              developing new services and products, providing event and promotional information, analyzing access
              frequency, updating app versions.
            </li>
          </ul>
          <ul className="leading-6 font-1 text-sm my-2">
            <li>
              <strong>2. Items of Personal Information Collected, Collection Methods, and Retention Period</strong>
              The Company processes and utilizes personal information within the retention period agreed upon by users.{' '}
            </li>
            <li>
              <strong>Categories and respective details include:</strong>
            </li>
            <li>
              · General Member: Profile picture, username, and login information are retained until membership
              withdrawal. <br /> · Legal Guardian Consent: Name and mobile number are retained until the user reaches
              the age of 19. <br />· Refund Account Information: Account holder's name, bank name, and account number
              are retained until the completion of payment and settlement.
            </li>
          </ul>
          <p className="leading-6 font-1 text-sm my-2">
            <strong>3. Handling of Personal Information of Children Under 14 Years Old</strong>
            The Company collects only the minimum personal information required for service provision upon obtaining
            legal guardian consent. This includes mobile phone authentication (name, mobile number) to verify the
            consent of a legal guardian.
          </p>

          <ul className="leading-6 font-1 text-sm my-2">
            <li>
              <strong>4. Retention and Destruction of Personal Information</strong>
              The Company promptly destroys personal information once the purpose of processing has been achieved or
              upon user request. However, certain information may be retained for a specified period as required by law.
            </li>
            <li>
              <strong>Retention Periods:</strong>
            </li>
            <li>
              · Records related to contracts or withdrawal of subscription: 5 years (Electronic Commerce Act) <br />·
              Records on payment and supply of goods: 5 years (Electronic Commerce Act) <br /> · Records on consumer
              complaints or dispute resolution: 3 years (Electronic Commerce Act) <br /> · Records on the display or
              advertisement: 6 months (Electronic Commerce Act) <br /> · Records on electronic financial transactions: 5
              years (Electronic Financial Transactions Act) <br /> · Service visit records: 3 months (Protection of
              Communications Secrets Act)
            </li>
          </ul>
          <ul className="leading-6 font-1 text-sm my-2">
            <li>
              <strong>5. Provision and Delegation of Personal Information to Third Parties</strong> The Company does not
              provide personal information to third parties without prior user consent. In cases where personal
              information needs to be provided, users are informed of the details and separate consent is obtained.
            </li>
            <li>
              <strong> Details on Third Party Provision:</strong>
            </li>
            <li>
              · The Company provides personal information to external partners to facilitate the use of external
              services, ensuring user convenience and service quality. These partners are subject to strict contractual
              obligations regarding personal information processing, including prohibition of personal information
              processing for purposes other than the contracted tasks, and measures to safeguard information.
            </li>
          </ul>
          <ul className="leading-6 font-1 text-sm my-2">
            <li>
              <strong>6. Rights of Users and Legal Representatives</strong> Users have the right to request access,
              correction, deletion, and suspension of processing of their personal information at any time. For minors
              under 14, such requests must be made by their legal guardians. Users over 14 can exercise their rights
              either personally or through a legal representative.
            </li>
            <li>
              <strong>How to Exercise Rights:</strong>
            </li>
            <li>
              · Access and Correction: Through settings {'>'} account {'>'}edit personal information. <br />· Deletion
              (Withdrawal): Through settings{'>'} account {'>'} delete account. <br /> · Withdrawal of Consent: Through
              settings {'>'} terms menu {'>'} withdraw consent.
            </li>
          </ul>
          <p className="leading-6 font-1 text-sm my-2">
            <strong> 7. Installation, Operation, and Rejection of Automatic Data Collection Devices</strong>
            The Company uses cookies and other tracking technologies to provide personalized services and optimize user
            experience.
          </p>
          <ul className="leading-6 font-1 text-sm my-2">
            <li>
              <strong>Cookie Information:</strong>
            </li>
            <li>
              · Purpose of Cookie Usage: To understand user preferences and provide customized services. <br /> · Cookie
              Installation and Operation: Users can choose to allow, check, or block cookies through browser settings.{' '}
              <br /> · ADID/IDFA: These identifiers are used to provide personalized advertisements and services.
            </li>
            <li>
              <strong>Cookie Management by Browser:</strong>
            </li>
            <li>
              · Internet Explorer: Select Tools {'>'} Internet Options {'>'} Privacy {'>'} Advanced {'>'} Choose to
              block or allow cookies. <br /> · Microsoft Edge: Select '...' {'>'} Settings {'>'} Privacy, search, and
              services {'>'} Choose level of tracking prevention. <br /> · Chrome: Click on '⋮' {'>'} Settings {'>'}{' '}
              Advanced {'>'} Privacy and security {'>'} Content settings {'>'} Cookies.
            </li>
          </ul>
          <p className="leading-6 font-1 text-sm my-2">
            <strong>8. Collection, Use, Provision, and Rejection of Behavioral Information</strong>
            The Company collects and uses behavioral information to provide customized services and advertisements.
          </p>
          <ul className="leading-6 font-1 text-sm my-2">
            <li>
              <strong>Details:</strong>
            </li>
            <li>
              · Information Collected: App usage history, purchase and search history, device information, advertising
              identifiers. <br /> · Purpose: To improve service quality and provide tailored services and benefits.
            </li>
          </ul>
          <p className="leading-6 font-1 text-sm my-2">
            <strong>9. Technical and Administrative Measures for Personal Information Protection</strong> The Company
            implements various measures to ensure the security and integrity of personal information, including
            encryption and access control.
          </p>
          <p className="leading-6 font-1 text-sm my-2">
            <strong> 10. Complaints Regarding Personal Information</strong>
            Users can address complaints regarding personal information by contacting the Company’s Data Protection
            Officer.
          </p>
          <ul className="leading-6 font-1 text-sm my-2">
            <li>
              <strong> Contact Information:</strong>
            </li>
            <li>
              · Name: Kim Min-je <br /> · Position: Team Leader <br />· Email: share@digitalnutrition.info <br />· 1:1
              Inquiry: Through the Soundpill app/web {'>'} Settings {'>'} 1:1 Inquiry
            </li>
          </ul>
          <p className="leading-6 font-1 text-sm my-2">
            <strong>11. Changes to the Privacy Policy</strong>
            This Privacy Policy is effective as of August 9, 2024.
          </p>
        </div>
      </main>
    );
  } else {
    return (
      <main className="w-full h-full overflow-y-auto no-scrolbar bg-N0">
        <div className="my-5 mx-[1rem] ">
          <p>사운드필 개인정보처리방침</p>
          <p>
            주식회사 디지털뉴트리션(이하 “회사”)은 개인정보보호 관한 법률을 준수하고 있으며, 이용자의 개인정보가
            보호받을 수 있도록 최선을 다하고 있습니다. 이에 개인정보보호 제30조에 따라 개인정보 처리에 관한 절차 및
            기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보
            처리방침을 수립•공개합니다.
          </p>
          <div className="my-3 leading-7">
            <p>개인정보처리방침은 관련 법률 및 지침의 변경과 내부 운영방침의 변경에 따라 변경될 수 있습니다.</p>
            <p>주요 개인정보 처리 표시 (라벨링)</p>
            <p>일반 개인정보 수집</p>
            <p>이름, 휴대전화번호, 생년월일 등</p>
            <p>※세부항목은 개인정보 처리방침 본문 확인</p>
            <p>개인정보 처리목적</p>
            <p>회원 관리, 서비스 제공 및 개발, 마케팅 활용(선택) 등</p>
            <p>자동 수집</p>
            <p>쿠키, ADID, IDFA</p>
            <p>쿠키, ADID, IDFA</p>
            <p>
              KCB, 카카오페이, AWS 등<br />
              <br />
              고충처리부서
            </p>
            <p>
              고객센터
              <br />
              사운드필 내 문의하기 또는
              <br />
              (이메일) share@digitalnutrition.info
            </p>
          </div>
          <p>개인정보처리방침은 다음과 같은 내용을 담고 있습니다.</p>
          <p>목차</p>
          <ul className="leading-7 my-2">
            <li>1. 개인정보 수집 및 이용 목적</li>
            <li>2. 수집하는 개인정보의 항목 및 수집 항목, 방법 및 보유기간</li>
            <li>3. 만 14세 미만 아동의 개인정보 처리에 관한 사항</li>
            <li>4. 개인정보의 보유이용기간 및 파기</li>
            <li>5. 개인정보의 제3자 제공 및 위탁 내용</li>
            <li>6. 이용자 및 법정대리인의 권리와 그 행사방법</li>
            <li>7. 개인정보 자동수집 장치의 설치 운영 및 그 거부에 관한 사항</li>
            <li>8. 행태정보의 수집·이용·제공 및 거부 등에 관한 사항</li>
            <li>9. 개인정보보호를 위한 기술적/제도적 관리</li>
            <li>10. 개인정보에 관한 민원 서비스</li>
            <li>11. 개인정보 처리방침의 변경</li>
          </ul>
          <p>
            <strong>1. 개인정보 수집 및 이용 목적</strong>
          </p>
          <p>회사는 이용자의 개인정보를 최소한으로 수집하며, 다음의 목적으로만 활용합니다.</p>
          <ul className="leading-7 my-2">
            <li>
              가. 회원관리
              <ul>
                <li>회원 서비스 제공 및 본인 확인(14세 미만 아동의 경우 법정대리인 동의여부 확인)</li>
              </ul>
            </li>
            <li>
              나. 서비스 제공을 위한 계약 이행 및 정산
              <ul>
                <li>콘텐츠 제공, 요금 결제, 물품 배송</li>
                <li>문의사항 처리</li>
              </ul>
            </li>
            <li>
              다. 서비스 개발 및 마케팅 활용
              <ul>
                <li>
                  이용자의 관심 및 성향 추정에 따른 맞춤형 컨텐츠 추천, 신규 서비스 및 상품 개발, 이벤트 정보 및 광고성
                  정보 제공, 마케팅 프로모션, 접속 빈도 파악, 앱 버전
                </li>
              </ul>
            </li>
          </ul>
          <p>
            <strong>2. 수집하는 개인정보의 항목 및 수집 항목, 방법 및 보유기간</strong>
          </p>
          <p>
            회사는 이용자로부터 개인정보 수집 시에 동의 받은 개인정보 보유기간 내에서 개인정보를 처리 및 이용합니다.
          </p>
          <ul>
            <li>
              <br />
              <ul>
                <li>가. 각각의 개인정보 처리사항은 다음과 같습니다.</li>
              </ul>
            </li>
          </ul>
          <table cellSpacing={0} cellPadding={0}>
            <tbody>
              <tr>
                <td valign="middle">
                  <p>구분</p>
                </td>
                <td valign="middle">
                  <p>수집목적</p>
                </td>
                <td valign="middle">
                  <p>수집항목</p>
                </td>
                <td valign="middle">
                  <p>보유기간</p>
                </td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>일반 회원</p>
                </td>
                <td valign="middle">회원가입(카카오톡, 네이버, 페이스북, 애플, 구글)</td>
                <td valign="middle">로그인 정보 식별 값, 프로필 사진, 필명</td>
                <td valign="middle">회원 탈퇴 시까지</td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>
                    <br />
                  </p>
                </td>
                <td valign="middle">
                  <p>회원가입</p>
                </td>
                <td valign="middle">
                  <p>필명, 프로필 사진, 아이디, 이름, 성별, 생년월일, 휴대전화번호, 국가</p>
                </td>
                <td valign="middle">회원탈퇴 또는 개인정보 유효기간 도래 시까지 보관</td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>
                    <br />
                  </p>
                </td>
                <td valign="middle">
                  <p>법정대리인 동의시</p>
                </td>
                <td valign="middle">
                  <p>이름, 휴대전화번호</p>
                </td>
                <td valign="middle">만 19세 이상 도래시 까지</td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>
                    <br />
                  </p>
                </td>
                <td valign="middle">
                  <p>본인 확인</p>
                </td>
                <td valign="middle">
                  <p>이름, 휴대전화번호</p>
                </td>
                <td valign="middle">회원탈퇴 또는 개인정보 유효기간 도래 시까지 보관</td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>
                    <br />
                  </p>
                </td>
                <td valign="middle">
                  <p>회원 정보 수정</p>
                </td>
                <td valign="middle">프로필 이미지, 필명, 휴대전화번호, 이메일</td>
                <td valign="middle">회원탈퇴 또는 개인정보 유효기간 도래 시까지 보관</td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>
                    <br />
                  </p>
                </td>
                <td valign="middle">상품 배송</td>
                <td valign="middle">이름, 휴대전화번호, 배송지 주소</td>
                <td valign="middle">재화·서비스 공급완료 및 요금결제·정산 완료시까지</td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>
                    <br />
                  </p>
                </td>
                <td valign="middle">상담 접수 시</td>
                <td valign="middle">
                  이메일, 휴대폰 번호, 단말 기기 OS 버전정보, 앱 버전, 인터넷 환경에 대해 고객에 게 제공하는 정보
                </td>
                <td valign="middle">3년</td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>
                    <br />
                  </p>
                </td>
                <td valign="middle">
                  <p>환불 계좌 수집 시</p>
                </td>
                <td valign="middle">
                  <p>예금주명, 은행명, 계좌번호</p>
                </td>
                <td valign="middle">재화·서비스 공급완료 및 요금결제·정산 완료시까지</td>
              </tr>
              <tr>
                <td valign="middle">기업서비스</td>
                <td valign="middle">기업 서비스 접수 시</td>
                <td valign="middle">
                  회사명, 담당자 성함, 직위, 이메일, 휴대폰 번호, 단말 기기 OS 버전정보, 앱 버전, 인터넷 환경에 대해
                  고객에 제공하는 정보
                </td>
                <td valign="middle">계약 만료 시까지</td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>
                    <br />
                  </p>
                </td>
                <td valign="middle">
                  <p>기업 회원가입</p>
                </td>
                <td valign="middle">아이디, 패스워드, 필명, 이메일, 휴대전화번호, 사용자 식별 값</td>
                <td valign="middle">회원탈퇴 또는 개인정보 유효기간 도래시 까지 보관</td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>
                    <br />
                  </p>
                </td>
                <td valign="middle">기업 회원 소속 등록 시</td>
                <td valign="middle">이름, 생년월일, 학교명, 이메일, 사용자 구분, 소속 기관 및 조직 정보</td>
                <td valign="middle">
                  <p>
                    <br />
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            <br />※ 선택항목은 입력을 하지 않아도 서비스 이용에 제한이 없습니다.
          </p>
          <div className="leading-7 my-2">
            <p>
              <strong> • 나. 허위 정보 입력 시 회사의 조치</strong>
            </p>
            <ul>
              <li>
                • 나. 허위 정보 입력 시 회사의 조치 <br /> • 이용자는 자신의 정보에 대해 정확성 및 적법성을 보장해야
                합니다. 만약 이를 위반하여 타인의 정보를 도용하는 등 각종 방법으로 허위 정보를 입력할 경우 회사는 해당
                이용자를 관계법령에 따라 신고할 수 있으며 강제 탈퇴를 시킬 수도 있습니다. <br />
                <strong>• 다. 서비스 이용과정에서 수집될 수 있는 정보</strong> <br /> • 이용자의 브라우저 종류 및 OS,
                접속 IP 정보, 접속로그, 쿠키 <br /> • (모바일기기 이용 시) 단말기 식별번호, 단말 OS 정보, PUSH 수신여부,
                ADID/IDFA <br /> • 콘텐츠 선택, 콘텐츠 기록 <br /> • 구매/결제/취소/환불 내역 <br /> • 라. 개인정보의
                수집 방법 <br /> • 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의하고 입력하는 경우
                <br /> • 고객센터 상담과정에서 수집 <br /> • 온/오프라인 이벤트에서 진행되는 이벤트 <br /> • 스마트폰용
                어플리케이션을 통한 수집 <br /> • ‘쿠키(cookie)’에 의한 정보 수집 <br /> • 외부 업체 서비스 등으로
                제3자로부터 제공
                <br /> • 제3자로부터 제공받는 개인정보 상세 정보
              </li>
            </ul>
          </div>
          <div className="leading-7">
            <p>
              <strong>3. 만 14세 미만 아동의 개인정보 처리에 관한 사항</strong>
            </p>
            <ul>
              <li>
                • 가. 회사는 만 14세 미만 아동에 대해 개인정보를 수집할 때 법정대리인의 동의를 얻어 해당 서비스 수행에
                필요한 최소한의 개인정보를 수집합니다. <br /> • 휴대폰 인증: 이름, 휴대전화번호 <br /> • 나. 회사는 만
                14세 미만 아동의 개인정보를 수집할 때에는 아동에게 법정대리인 성명, 연락처와 같이 최소한의 정보를 요구할
                수 있으며, 다음의 방법으로 적법한 법정대리인이 동의하였는지를 확인합니다. <br /> • 동의 내용을 게재한
                인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 법정대리인의 휴대전화 본인인증을 통해 본인
                여부를 확인하는 방법
              </li>
            </ul>
          </div>
          <div className="leading-7">
            <p>
              <strong>4. 개인정보의 보유-이용기간 및 파기</strong>
            </p>
            <ul>
              <li>
                • 가. 회사는 개인정보 처리목적 달성, 이용자의 서비스 해지 요청이 있을 경우 지체없이 해당 개인정보를
                파기합니다. <br /> • 나. 단, 관계법령에 의한 일정기간 보관 의무를 부과하는 경우에는 해당 기간 동안
                개인정보를 안전하게 보관합니다. <br /> • 계약 또는 청약철회 등에 관한 기록 (전자상거래법 :5년) <br /> •
                대금결제 및 재화 등의 공급에 관한 기록 (전자상거래법 5년) <br /> • 소비자의 불만 또는 분쟁처리에 관한
                기록 (전자상거래법 3년) <br /> • 표시/광고에 관한 기록 (전자상거래법 6개월) <br /> • 전자금융 거래에
                관한 기록 (전자금융거래법 5년) <br /> • 서비스 방문 기록 (통신비밀보호법 3개월) <br /> • 다. 개인정보
                파기방법은 아래와 같습니다. <br /> • 전자적 파일 형태: 복구 및 재생할 수 없는 기술적 방법을 사용하여
                안전하게 삭제 <br /> • 종이에 출력된 개인정보: 분쇄하거나 소각하여 파기 <br /> • 라. 개인정보 유효기간
                제도 <br /> • 회사는 5년간 서비스를 이용하지 않는 회원의 개인정보를 파기하여 관리합니다.
              </li>
            </ul>
          </div>
          <div className="leading-7">
            <p>
              <strong>5. 개인정보의 제3자 제공 및 위탁 내용</strong>
            </p>
            <ul>
              <li>
                • 가. 회사는 이용자의 개인정보를 사전 동의 없이 제3자에게 제공하지 않습니다. <br /> • 이용자의 개인정보
                제공이 필요한 경우, 사전에 이용자에게 ‘개인정보를 제공받는 자, 제공목적, 제공하는 개인정보 항목,
                제공받는 개인정보의 보유 및 이용기간’을 고지하고 이에 대해 별도 동의를 구합니다. <br /> • 다만. 관련
                법령에서 달리 정하는 경우에는 고객의 동의 없이 개인정보를 제공하는 것이 가능합니다. <br /> • 나. 회사는
                이용자의 외부 제휴사 서비스 이용을 위해 개인정보를 제공하고 있으며, 편리하고 더 나은 서비스 제공을 위해
                개인정보 처리 업무를 외부 전문 업체에 위탁하여 운영하고 있습니다. <br /> • 위탁 체결 시 ‘개인정보
                보호법’ 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적관〮리적 보호조치, 재 위탁 제한,
                수탁자에 대한 관리감〮독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를
                안전하게 처리하는지를 감독하고 있습니다. <br /> • 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이
                본 개인정보 처리방침을 통하여 공개합니다. <br /> • 제3자 제공 상세 정보 <br /> 위탁 상세 정보 <br /> •
                다. 회사는 정부 관계부처가 합동으로 발표한 ‘긴급상황 시 개인정보 처리 및 보호수칙’에 따라 재난, 감염병,
                급박한 생명신〮체 위험을 초래하는 사건사〮고, 급박한 재산 손실 등의 긴급상황이 발생하는 경우 정보주체의
                동의 없이 관계기관에 개인정보를 제공할 수 있습니다. 자세한 사항은 개인정보보호 위원회의 가이드라인을
                확인하시기 바랍니다.
              </li>
            </ul>
          </div>
          <div className="leading-7">
            <p>
              <strong></strong> 6. 이용자 및 법정대리인의 권리와 그 행사방법
            </p>
            <li>
              • 가. 이용자의 권리 <br /> • 이용자는 회사에 대해 언제든지 개인정보 열람정〮정삭〮제처〮리정지 요구 등의
              권리를 행사할 수 있습니다. <br /> • 단, 만 14세 미만 아동에 관한 개인정보 열람 등 요구는 법정대리인이 직접
              해야 하며, 만 14세 이상의 미성년자인 이용자는 이용자의 개인정보에 관하여 미성년자 본인이 권리를 행사하거나
              법정대리인을 통하여 권리를 행사할 수도 있습니다. <br /> • 나. 이용자의 권리 행사 방법 <br /> • 메뉴 이용
              방법
            </li>
            <p>
              권리, 행사 방법 열람, 정정, ※ 설정 {'>'} 계정 {'>'}개인정보 수정 클릭d 후 열람 및 정정 삭제(탈퇴), <br />※
              설정 {'>'} 계정 {'>'} 계정 삭제 클릭 후 회원탈퇴 절차 진행 동의 철회, <br /> ※ 설정 {'>'}약관 메뉴 접속 후
              열람 및 정정 <br /> ※ 설정 {'>'}약관 메뉴 접속 후 “마케팅 수신 동의” 선택 동의 철회 <br /> • 회사에 열람,
              정정, 삭제, 처리정지, 동의 철회 요구 방법 본 개인정보처리방침 9조 개인정보에 관한 민원서비스에 기재된
              고객센터 및 회사의 개인정보 보호책임자에게 서면, 전화 또는 이메일로 연락하시면 본인 확인 후 지체 없이
              조치하겠습니다. 다만, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수
              없습니다. <br /> ※ 이용자의 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수
              있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야
              합니다.
            </p>
          </div>
          <div>
            <p>
              <strong>
                7. 개인정보 자동수집 장치의 설치 운영 및 그 거부에 관한 사항 <br /> 회사는 개인화된 서비스를 제공하기
                위하여 ‘쿠키(cookie)’ 및 ADID/IDFA를 수집할 수 있습니다.
              </strong>
            </p>
            <ul>
              <li>
                • 가. 쿠키(cookie)란? <br /> • 회사의 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게
                보내는 소량의 정보이며 이용자들의 PC컴퓨터내의 하드디스크에 저장되기도 합니다. <br /> • 나. 쿠키(Cookie)
                사용 목적 <br /> • 이용자가의 취향과 관심분야를 파악하여 개인형 맞춤화 된 서비스를 제공하기 위해
                사용됩니다. <br /> • 이용자의 디바이스에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고
                최적화된 서비스를 제공합니다. <br /> • 다. 쿠키(Cookie) 설치운〮영 및 거부 <br /> • 이용자는 쿠키 설치에
                대한 선택권을 가지고 있습니다. 따라서 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를
                허용하거나, 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. <br /> •
                다만, 쿠키의 저장을 거부할 경우에는 서비스 제공에 어려움이 있을 수 있습니다. <br /> • 쿠키(Cookie) 설치
                허용거〮부하는 방법
              </li>
              <li>
                브라우저, 방법 <br /> • 인터넷 익스플로러, Internet Explorer에서 도구 버튼을 선택한 다음 인터넷 옵션을
                선택 <br /> • 개인 정보 탭을 선택하고 설정에서 고급을 선택한 다음 쿠키의 차단 또는 허용을 선택 <br /> •
                Microsoft Edge, Edge에서 오른쪽 상단 ‘····’· 표시를 클릭한 후, 설정을 클릭 <br /> • 설정 페이지 좌측의
                ‘개인정보, 검색 및 서비스’를 클릭 후 추적방지 섹션에서 ‘추적방지’ 여부 및 수준을 선택 <br /> •
                ‘InPrivate를 검색할 때 항상 “엄격” 추적 방지 사용’ 여부 선택 <br />• 아래 개인정보 섹션에서 ‘추적 안함
                요청 보내기’ 여부 선택 <br /> • 크롬 브라우저, Chrome 오른쪽 상단 ‘ ⋮ ’ 표시(chrome 맞춤설정 및 제어)를
                클릭한 후, 설정 표시 클릭 <br /> • 설정 페이지 하단에 ‘고급 설정 표시’를 클릭하고 개인정보 섹션에서
                콘텐츠 설정을 클릭 <br /> • 쿠키 섹션에서 ‘타사 쿠키 및 사이트 데이터 차단’의 체크박스를 선택 <br /> •
                Safari, MacOS 상단 좌측 메뉴바에서 [Safari] -{'>'} [환경설정]을 선택 <br /> • [환경설정] 창에서
                [보안]으로 이동하여 쿠키 허용여부 선택
              </li>
              <li>
                • 라. ADID/IDFA란? <br /> • ADID(Android OS)/IDFA(iOS)란 모바일 앱 이용자의 광고 식별 값입니다. <br /> •
                마. ADID/IDFA 사용 목적 <br /> • 온라인 맞춤형 광고 등 이용자에게 최적화된 맞춤형 서비스 및 혜택을
                제공하기 위하여 수집 및 이용됩니다. <br /> • 바. ADID/IDFA 수집 방법 및 보유기간 <br /> • 이용자가 앱
                방문/실행 시 자동 수집합니다. <br /> • 보유 기간은 수집일로부터 1년이며, 1년이 지난 정보는 지체없이
                파기합니다. <br /> • 사. ADID/IDFA 수집 및 거부 <br /> • 이용자는 ADID/IDFA 수집이〮용에 대한 선택권을
                가지고 있습니다. 따라서 이용자는 모바일 단말기의 설정 변경을 통해 앱의 맞춤형 광고를 차단허〮용할 수
                있습니다. <br /> • ADID/IDFA 수신거부하는 방법
              </li>
              <li>
                단말기, 방법 • 안드로이드 (Android), 설정에서 개인정보보호 클릭 후 광고 탭에서 광고 ID 재설정 또는
                광고ID 삭제 또는 설정에서 구글(구글설정) 클릭 후 광고에서 광고 맞춤설정 선택 및 해제 <br /> •
                아이폰 (iOS), 설정에서 개인정보보호 클릭 후 추적에서 앱이 추적을 요청하도록 허용 끔 클릭 또는 설정에서
                개인정보보호탭 클릭 후 광고에서 광고 추적 제한 설정 <br /> • ※ 모바일 OS 버전에 따라 메뉴 및 방법이 다소
                상이할 수 있습니다. <br /> • 아. 이용자 피해 구제 방법 <br /> • 제10조(개인정보에 관한 민원 서비스)의
                기재된 내용을 참조하셔서 문의할 수 있습니다.
              </li>
            </ul>
          </div>
          <div className="leading-7">
            <p>
              <strong>8. 행태정보의 수집·이용·제공 및 거부 등에 관한 사항</strong>
            </p>
            <ul>
              <li>
                • 가. 회사는 서비스 이용과정에서 정보주체에게 맞춤서비스 제공이나 더 나은 환경의 서비스 및 광고를
                제공하기 위하여 행태정보를 수집·이용하고 있습니다. 수집하는 행태정보의 항목, 행태정보 수집 방법,
                행태정보 수집 목적, 보유·이용기간 및 이후 정보처리 방법 앱 서비스 방문이력, 사용이력, 구매 및 검색이력,
                기기정보, 광고식별자, 기타 온라인상 활동정보, 이용자가 웹사이트를 방문하거나 앱을 실행할 때 분석 툴을
                통해 자동수집 및 전송, 서비스 품질 개선을 위한 통계 분석, 맞춤형 서비스 및 혜택 제공, 온라인 맞춤형 광고
                제공, 최대 18개월 이내 파기(보유기간 이후 파기 또는 조회 불가 처리) <br /> • 나. 회사는 다음과 같이
                온라인 맞춤형 광고 사업자가 행태정보를 수집·처리하도록 허용하고 있습니다. 행태정보를 수집 및 처리하려는
                광고 사업자, 행태정보 수집 방법, 수집·처리되는 행태정보 항목, 보유·이용기간 Google, Meta, AppLovin,
                이용자가 웹사이트를 방문하거나 앱을 실행할 때 분석 툴을 통해 자동수집 및 전송, 앱 서비스 방문 이력,
                사용이력, 접속 및 가입 경로, 기기 정보, 광고식별자, 기타 온라인상 활동 정보, 최대 18개월 이내
                파기(보유기간 이후 파기 또는 조회 불가 처리) <br /> • 다. 회사는 온라인 맞춤형 광고 등에 필요한 최소한의
                행태정보만을 수집하며, 사상, 신념, 가족 및 친인척관계, 학력·병력, 기타 사회활동 경력 등 개인의
                권리·이익이나 사생활을 뚜렷하게 침해할 우려가 있는 민감한 행태정보를 수집하지 않습니다. <br /> • 라.
                회사는 만 14세 미만임을 알고 있는 아동이나 만14세 미만의 아동을 주 이용자로 하는 온라인 서비스로부터
                맞춤형 광고 목적의 행태정보를 수집하지 않고, 만 14세 미만임을 알고 있는 아동에게는 맞춤형 광고를
                제공하지 않습니다. <br /> • 마. 회사는 모바일 앱에서 정보주체에게 맞춤서비스 제공이나 더 나은 환경의
                광고를 제공하기 위하여 광고식별자를 수집•이용합니다. 광고식별자란 모바일 단말기에서 부여되는 광고용 고유
                식별 값입니다. 고객은 모바일 단말기의 설정 변경을 통해 앱의 맞춤형 광고를 차단•허용할 수 있습니다. 단,
                차단 설정 시 맞춤형 서비스 이용에 어려움이 있을 수 있습니다. <br /> • 바. 거부방법 <br /> • ①
                (안드로이드) 설정 → 개인정보보호 → 광고 → 광고 ID 재설정 또는 광고 ID 삭제 • ② (아이폰) 설정 →
                개인정보보호 → 추적 → 앱이 추적을 요청하도록 허용 끔 • ※ 모바일 OS 버전에 따라 메뉴 및 방법이 다소
                상이할 수 있습니다.
              </li>
            </ul>
          </div>
          <div className="leading-7">
            <p>
              <strong>
                9. 개인정보보호를 위한 안전성 확보조치 회사는 고객의 개인정보를 처리함에 있어 개인정보가 분실, 도난,
                누출, 변조 훼손되지 않도록 안전성 확보를 위하여 기술적, 관리적, 물리적 대책을 강구하고 있습니다.
              </strong>
            </p>
            <ul>
              <li>
                • 가. 기술적 대책 <br /> • 이용자의 개인정보는 암호화된 통신구간을 이용하여 전송하고, 비밀번호 등 중요
                정보는 암호화하여 보관하고 있습니다. <br /> • 백신프로그램을 이용하여 컴퓨터 바이러스에 의해 피해를
                방지하기 위한 조치를 취하고 있습니다. <br /> • 해킹 등 외부 침입에 대비하여 취약점 분석 등을 이용하여
                보안에 만전을 기하고 있습니다. <br /> • 나. 관리적 대책 <br /> • 개인정보에 대한 접근권한을 최소한의
                인원으로 제한하고 있습니다. 또한, 해당 직원에게 보안서약서와 정기적인 교육 및 캠페인을 통하여 정보유출을
                사전에 방지하고 있습니다. <br /> • 개인정보의 안전한 처리를 위한 내부 관리계획을 수립하였으며, 이에 대한
                이행사항 및 직원의 준수여부를 감시하기 위한 내부절차를 마련하고 있습니다. <br /> • 다. 물리적 대책{' '}
                <br /> • 전산실 등의 접근 통제
              </li>
            </ul>
          </div>
          <div className="leading-7">
            <p>
              <strong>10. 개인정보에 관한 민원 서비스</strong>
            </p>
            <ul>
              <li>
                • 가. 회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만사항 및 문의를 처리하기 위하여 아래와 같이
                관련 부서 및 개인정보보호책임자를 지정하고 있습니다. 개인정보보호 책임자, 고객센터 <br /> • 성명:
                김민제  <br />• 직위: 팀장  <br />• 이메일: share@digitalnutrition.info  <br />• 1:1 문의하기: 사운드필
                앱/웹 {'>'} 설정 {'>'} 1:1문의하기 링크{')'}  https://soundpill.app/app/setting/inquiry?page=1 <br /> •
                나. 기타 개인정보침해에 대한 신고 또는 상담이 필요하신 경우에는 아래 기관으로 문의하시기 바랍니다.
              </li>
            </ul>
          </div>
          <table cellSpacing={0} cellPadding={0} className="leading-7">
            <tbody>
              <tr>
                <td valign="middle">
                  <p>개인정보침해신고센터</p>
                </td>
                <td valign="middle">
                  <p>개인정보분쟁조정위원회</p>
                </td>
                <td valign="middle">
                  <p>대검찰청</p>
                </td>
                <td valign="middle">
                  <p>경찰청</p>
                </td>
              </tr>
              <tr>
                <td valign="middle">
                  <p>
                    privacy.kisa.or.kr
                    <br />
                    (국번없이 118)
                  </p>
                </td>
                <td valign="middle">
                  <p>
                    www.kopico.go.kr
                    <br />
                    (국번없이 1833-6972)
                  </p>
                </td>
                <td valign="middle">
                  <p>
                    spo.go.kr
                    <br />
                    (국번없이 1301)
                  </p>
                </td>
                <td valign="middle">
                  <p>
                    ecrm.cyber.go.kr
                    <br />
                    (국번없이 182)
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="leading-7">
            <ul>
              <li>
                <strong>11. 개인정보 처리방침의 변경</strong>
              </li>
              <li> 이 개인정보 처리방침은 2024. 08. 09부터 적용됩니다. 2024년 08월 09일</li>
            </ul>
          </div>
        </div>
      </main>
    );
  }
}
