import { useInfiniteQuery } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AddFolderSVG, ArrowLeftSVG, EditorPlayListSVG, EditorPlaySVG, LikeEmptySVG } from '../../assets';
import AuthAxios from '../../axios';
import IconButton from '../../components/IconButton';
import AlbumModal from '../../components/modals/AlbumModal';
import EditorPlayListModal from '../../components/modals/EditPlayListModal';
import { CustomTrackCompV2 } from '../../components/Music';
import { ModalContext } from '../../contexts/ModalContext';
import { MusicPlayerStateContext } from '../../contexts/MusicContext';
import { Track, ResponseType } from '../../types';
import { miracleCategoryList } from '../../utils/data';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import { transLn } from '../../utils/transLn';
import { EDITOR_Z_IDX } from '../../utils/constant';

export default function MainPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sort = params.get('sort');
  const order = params.get('order');
  const durations = params.get('durations') ?? 0;

  const { id } = useParams();
  const { ref, inView } = useInView();
  const navigate = useNavigate();

  const [currentDuration, setCurrentDuration] = useState(Number(durations));
  const [currentSort, setCurrentSort] = useState(sort ?? 'createdAt');
  const [currentOrder, setCurrentOrder] = useState(order ?? 'DESC');
  const fetchCatetoryTrack = async ({ pageParam }: { pageParam: number }): Promise<ResponseType<Track>> => {
    const response = await AuthAxios.get<ResponseType<Track>>(
      `/web/track/track-by-category?page=${pageParam}&perPage=30&categoryId=${convertCategoryToId(
        id!
      )}&order=${currentOrder}&sort=${currentSort}${currentDuration === 0 ? `` : `&durations=${currentDuration}`}`
    );
    return response.data;
  };
  const {
    data,
    // error,
    fetchNextPage,
    hasNextPage,
    // isFetching,
    isFetchingNextPage,
    // status,
  } = useInfiniteQuery({
    queryKey: ['/web/track/track-by-category', id, currentSort, currentOrder, currentDuration],
    queryFn: fetchCatetoryTrack,
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if (lastPage.list.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    staleTime: 36000, // 10분 동안 캐시 유지
  });
  const trackList = data?.pages.flatMap((page) => page.list) || [];

  const { handleModal } = useContext(ModalContext);
  const {
    onChangePlayListOrder,
    onChangePlayListOrderShuffle,
    onChangePlayListJustAdd,
    onChangePlayListAdd,
    onChangeMusic,
  } = useContext(MusicPlayerStateContext);

  const [selectState, setSelectState] = useState(false);

  const [selectedTrackList, setSelectedTrackList] = useState<Track[]>([]);

  /** 전체듣기 */
  const onClickPlayAll = () => {
    handleModal(
      <EditorPlayListModal
        onClickAddPlayAll={() => {
          onChangePlayListAdd(trackList);
        }}
        onCLickResetPlayAll={() => {
          onChangePlayListOrder(trackList);
        }}
      />
    );
  };

  const onClickComplite = () => {
    setSelectState(false);
    setSelectedTrackList([]);
  };

  /** 선택 */
  const onClickSelect = () => {
    setSelectState(true);
  };

  /** 전체선택 */
  const onClickSelectAll = () => {
    setSelectedTrackList(trackList);
  };

  /** 전체해제 */
  const onClickDeSelectAll = () => {
    setSelectedTrackList([]);
  };

  /** 현재 선택한 곡 바로 재생 */
  const onClickPlayNow = () => {
    if (selectedTrackList.length) {
      handleModal(
        <EditorPlayListModal
          onClickAddPlayAll={() => {
            onChangePlayListAdd(selectedTrackList);
            onClickComplite();
          }}
          onCLickResetPlayAll={() => {
            onChangePlayListOrder(selectedTrackList);
            onClickComplite();
          }}
        />
      );
    }
  };

  /** 앨범 모달 열기. */
  const onClickEditAlbum = () => {
    handleModal(<AlbumModal selectedTrackList={selectedTrackList} />);
  };

  const onClicklikeTracks = () => {
    AuthAxios.post(`/web/like/tracks`, {
      trackIds: selectedTrackList.map((item) => item.id),
    })
      .then((res) => {
        console.log('완료');
        alert('완료');
        window.location.reload();
        // setCurrentPlayMusic((prev) => {
        //   return { ...prev!, isLiked: true };
        // });
        // // 플레이리스트에서도 찾아서 값을 변경.
        // setPlayList((prevList) => {
        //   const updatedList = prevList.map((item) => {
        //     if (item.id === currentPlayMusic.id) {
        //       return { ...item, isLiked: true };
        //     }
        //     return item;
        //   });
        //   return updatedList;
        // });
      })
      .catch((error) => {
        console.log(error);
      });
    // handleModal(<AlbumModal selectedTrackList={selectedTrackList} />);
  };
  /** 트랙 클릭 이벤트 */
  const onClickTrack = (item: Track) => {
    console.log('onClickTrack1', item);
    if (selectState) {
      if (selectedTrackList.some((track) => track.id === item.id)) {
        setSelectedTrackList((prev) => prev.filter((track) => track.id !== item.id));
      } else {
        setSelectedTrackList((prev) => {
          return [...prev, item];
        });
      }
    } else {
      onChangeMusic(item);
    }
  };

  /** 랜덤 재생 */
  const onClickPlayAllRandomly = () => {
    handleModal(
      <EditorPlayListModal
        onClickAddPlayAll={() => {
          onChangePlayListAdd(trackList);
        }}
        onCLickResetPlayAll={() => {
          onChangePlayListOrderShuffle(trackList);
        }}
      />
    );
  };

  const onClickSort = (value: string) => {
    console.log('sdfsdfsdfsfdsfdsf', value);
    // setCurrentSort(value);
    if (value === 'newest') {
      setCurrentSort('createdAt');
      setCurrentOrder('DESC');
    } else if (value === 'popularity') {
      setCurrentSort('likeCount');
      setCurrentOrder('DESC');
    } else if (value === 'oldest') {
      setCurrentSort('createdAt');
      setCurrentOrder('ASC');
    } else if (value === 'alphabetical') {
      setCurrentSort('titleKo');
      setCurrentOrder('ASC');
    }
  };

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);
  useEffect(() => {
    setCurrentDuration(0);
    setCurrentSort('createdAt');
    setCurrentOrder('DESC');
    setSelectedTrackList([]);
  }, [id]);

  return (
    <div className="w-full h-full overflow-y-auto no-scrollbar pb-[1.875rem]">
      <div className="flex justify-between mb-[1.375rem] px-4 md:px-10 md:mt-12">
        <div className="flex items-center gap-2">
          {/* <IconButton onClick={() => navigate(-1)}>
            <ArrowLeftSVG />
          </IconButton> */}
          <span className="text:md lg:text-2xl font-bold text-N1">
            {t(`navigator.${id}`)}
            {/* {id} */}
          </span>
        </div>
        <div className="flex justify-start gap-5 text-N1 max-md:hidden">
          {selectState ? (
            <>
              {selectedTrackList.length === 0 ? (
                <button onClick={onClickSelectAll}>전체선택</button>
              ) : (
                <button onClick={onClickDeSelectAll}>전체해제</button>
              )}
              <MenuBtn className="md:bg-N9" onClick={onClickComplite}>
                {t('category.completed')}
              </MenuBtn>
            </>
          ) : (
            <>
              <MenuBtn onClick={onClickSelect}>{t('situation.select')}</MenuBtn>
              {/* <MenuBtn onClick={onClickPlayAll}>
                {t("category.playAll")}
              </MenuBtn>
              <MenuBtn onClick={onClickPlayAllRandomly}>
                {" "}
                {t("category.randomPlay")}
              </MenuBtn>
              <MenuBtn
                onClick={() => {
                  navigate(`/app/category/${id}/info`);
                }}
              >
                {t("category.effectOverview")}
              </MenuBtn> */}
              <select
                className="text-sm bg-N6 rounded-full text-N1"
                // value={currentSort}
                defaultValue={'newest'}
                onChange={(e) => onClickSort(e.target.value)}
              >
                {/* 최신순 */}
                <option className="text-[#191919]" value="newest">
                  {t('category.newest')}
                </option>
                {/* 인기순 */}
                <option value="popularity">{t('category.popularity')}</option>
                {/* 오래된순 */}
                <option value="oldest">{t('category.oldest')}</option>
                {/* 가나다순 */}
                <option value="alphabetical">{t('category.alphabetical')}</option>
              </select>
            </>
          )}
        </div>
      </div>

      {id === 'minit_miracle' && (
        <div className="flex w-full gap-2 px-2 overflow-x-auto no-scrollbar md:px-10">
          {miracleCategoryList.map((category, index) => (
            <button
              onClick={() => setCurrentDuration(category.code)}
              key={`category-filter-${index}`}
              className="px-4 min-w-max h-[40px] md:h-[36px] text-N2 border border-N2 rounded-full bg-N7 text-[15px] md:bg-N9"
              style={{
                border: currentDuration === category.code ? '' : 'none',
              }}
            >
              <div>{transLn(category.value)}</div>
            </button>
          ))}
        </div>
      )}
      <hr className="my-2 md:invisible" />
      <div className="flex justify-between w-full px-4 mb-4 md:px-10 md:hidden">
        <div className="border rounded-lg bg-N7 p-1 h-fit">
          <select
            className="text-sm bg-transparent text-N0"
            // value={currentSort}
            defaultValue={'newest'}
            onChange={(e) => onClickSort(e.target.value)}
          >
            {/* 최신순 */}
            <option value="newest">{t('category.newest')}</option>
            {/* 인기순 */}
            <option value="popularity">{t('category.popularity')}</option>
            {/* 오래된순 */}
            <option value="oldest">{t('category.oldest')}</option>
            {/* 가나다순 */}
            <option value="alphabetical">{t('category.alphabetical')}</option>
          </select>
        </div>

        <div className="flex gap-1 sm:gap-3 sm:w-full">
          <MenuBtn onClick={onClickPlayAll} className="text:xs">
            <p>{t('category.playAll')}</p>
          </MenuBtn>
        </div>
      </div>

      <div className="grid w-full px-2 md:px-10 gap-x-2 gap-y-4 md:gap-x-4 md:gap-y-6 h-fit max-md:grid-cols-2 md:grid-cols-4 xl:grid-cols-6">
        {trackList.map((track, index) => (
          <CustomTrackCompV2
            onClick={() => onClickTrack(track)}
            className={`w-full aspect-square  rounded-[22px] ${
              selectedTrackList.some((item) => item.id === track.id) && 'border-2'
            }`}
            key={`${track.id}-${index}`}
            track={track}
            durationVisible={true}
          />
        ))}
      </div>
      {/* <button
        ref={ref}
        onClick={() => fetchNextPage()}
        disabled={!hasNextPage || isFetchingNextPage}
      >
        {isFetchingNextPage
          ? "Loading more..."
          : hasNextPage
            ? "Load Newer"
            : "Nothing more to load"}
      </button> */}

      {selectState && selectedTrackList.length > 0 && (
        <div
          style={{
            zIndex: EDITOR_Z_IDX,
          }}
          className="absolute left-0 right-0 mx-auto bottom-0 md:top-0 w-full max-md:rounded-t-lg md:rounded-b-lg md:w-[512px] h-[80px] md:h-[48px] text-white bg-N10 flex justify-around"
        >
          <IconButton onClick={onClickPlayNow}>
            <EditorPlaySVG />
          </IconButton>
          {/* <IconButton
            onClick={() => onChangePlayListJustAdd(selectedTrackList)}
          >
            <EditorPlayListSVG />
          </IconButton> */}
          {/* <IconButton onClick={onClickEditAlbum}>
            <AddFolderSVG />
          </IconButton> */}
          {/* <IconButton onClick={onClicklikeTracks}>
            <LikeEmptySVG />
          </IconButton> */}
        </div>
      )}
    </div>
  );
}

interface IMenuBtnProps extends React.ComponentPropsWithoutRef<'button'> {}
function MenuBtn(props: IMenuBtnProps) {
  return (
    <button
      {...props}
      className={`flex items-center px-2 sm:px-4 sm:py-2 sm:text-base rounded-full bg-N7 text-N1 drop-shadow-lg ${props.className}`}
    />
  );
}

export function convertCategoryToId(category: string) {
  if (category === 'minit_miracle') {
    return 1;
  }
  if (category === 'sleep') {
    return 2;
  }
  if (category === 'diet') {
    return 3;
  }
  if (category === 'concentration') {
    return 4;
  }
  if (category === 'stress') {
    return 5;
  }
  if (category === 'experience') {
    return 6;
  }
}
