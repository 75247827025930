import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { GoogleLogin } from '../../assets';

export default function GoogleLoginBtn({
  afterSocialLoginSuccess,
}: {
  afterSocialLoginSuccess: ({ type, socialId, email }: { type: string; socialId: string; email: string }) => void;
}) {
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const res = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
              Accept: 'application/json',
            },
          }
        );
        if (res.status === 200) {
          afterSocialLoginSuccess({
            type: 'google',
            socialId: res.data.id,
            email: res.data.email,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    onError: () => {
      console.log('login fail');
    },
  });
  return (
    <button onClick={() => login()} className="flex items-center justify-center w-12 h-12 bg-white rounded-lg ">
      <GoogleLogin />
    </button>
  );
}
