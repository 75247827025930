import { Route, Routes } from 'react-router-dom';
import InfoPage from './Info';
import MainPage from './Main';
import PlayListPage from './PlayList';

export default function OnePageCategoryRoot() {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/info" element={<InfoPage />} />
      <Route path="/playlist" element={<PlayListPage />} />
      <Route path="/*" element={<div>Not Found</div>} />
    </Routes>
  );
}
