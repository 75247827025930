import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LOGOSVG } from '../../assets';
import AuthAxios from '../../axios';
import NaverLoginBtn from '../../components/socialLogin/NaverLoginBtn';
import GoogleLoginBtn from '../../components/socialLogin/GoogleLoginBtn';
import KakaoLoginBtn from '../../components/socialLogin/KakaoLoginBtn';
import FacebookLoginBtn from '../../components/socialLogin/FacebookLoginBtn';

import OpenAppBtn from '../../components/common/OpenAppBtn';
import ExploreBtn from '../../components/common/ExploreBtn';
import { useTranslation } from 'react-i18next';
import AppleLoginBtn from '../../components/socialLogin/AppleLoginBtn';
import { ModalContext } from '../../contexts/ModalContext';
import SocialLoginButtons from '../../components/socialLogin/SocialLoginButtons';

export default function ForgotPasswordPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleModal } = useContext(ModalContext);

  const [email, setEmail] = useState('');

  const handlePostVerifications = () => {
    AuthAxios.post('/web/verifications/2', {
      division: 'email',
      type: 'findPassword',
      value: email,
    })
      .then((resolve) => {
        handleModal(
          <div className="w-[300] bg-N10 flex flex-col items-center rounded-[22px] text-N0 p-2 px-4">
            <div>{resolve.data.description ?? '이메일로 인증요청을 전송했습니다.'}</div>
            <button
              className="w-full bg-N5 text-N10 rounded-[22px] text-base font-semibold"
              onClick={() => handleModal()}
            >
              확인
            </button>
          </div>
        );
      })
      .catch((error) => {
        console.log(error);
        handleModal(
          <div className="w-[300] bg-N10 flex flex-col items-center rounded-[22px] text-N0 p-2 px-4">
            <div>{error.response.data.message ?? ''}</div>
            <button
              className="w-full bg-N5 text-N10 rounded-[22px] text-base font-semibold"
              onClick={() => handleModal()}
            >
              확인
            </button>
          </div>
        );
      });
  };
  return (
    <div className="flex flex-col items-center justify-start w-full">
      <div className="flex items-center justify-between w-full px-5 mt-5 md:flex-row-reverse mb-[128px]">
        <LOGOSVG className="md:hidden w-[93px] " />
        <div className="flex gap-[6.75px]">
          <OpenAppBtn />
          <ExploreBtn />
        </div>
      </div>

      <div className="flex flex-col items-center  text-N0 leading-[26px] mb-[21px] md:text-xl">
        {/* font pretendard */}
        <LOGOSVG className="max-md:hidden mb-[34px] md:w-[163px]" />
        <h1 className="text-[22px] font-medium">{t('web.resetPassword')}</h1>
        <p className="text-[18px]">{t('web.enterEmail')}</p>
        <p className="text-[18px]">{t('web.resetEmailInfo')}</p>
      </div>

      <form
        className="flex flex-col items-center w-full gap-4 mb-[22px]"
        onSubmit={(e) => {
          e.preventDefault();
          console.log('email', email);
          handlePostVerifications();
          // handleModal(<FindPwLinkModal />);
        }}
      >
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          placeholder={t('web.emailAddress')}
          className="focus:outline-none w-[288px]  h-[48px]  rounded-lg p-3 text-base text-N3 focus:placeholder:drop-shadow-md md:w-[360px] md:h-[56px]"
        />
        <button
          type="submit"
          disabled={email.length === 0}
          className="rounded-lg w-[288px] h-[48px] text-N2 leading-6 md:w-[360px] md:h-[56px]"
          style={{
            textAlign: 'center',
            backgroundImage: 'linear-gradient(160deg, #473CBC, #202E67)',
          }}
        >
          {t('web.send')}
        </button>
      </form>

      <div className="w-[288px] h-[18px] flex items-center justify-between mb-[39px] md:w-[360px]">
        <div className="w-[117.33px] h-[1px] bg-N3 md:w-[152px]" />
        {/* font Noto Sans KR */}
        <span className="text-[13px] leading-[18px] text-N3">{t('web.or')}</span>
        <div className="w-[117.33px] h-[1px] bg-N3 md:w-[152px]" />
      </div>

      <div className=" w-[288px] h-[82px]  flex flex-col items-center justify-between mb-[56px]">
        <span className="text-[15px] text-N1 font-medium">{t('web.socialLogin')}</span>
        <div className="flex justify-between w-full h-[48px]">
          <SocialLoginButtons />
        </div>
      </div>

      <span className="text-[15px] font-medium text-N1 mb-[92px]">
        <Link to={'/auth'} className="underline ">
          {t('web.loginWithEmail')}
        </Link>
      </span>
    </div>
  );
}
