import { PropsWithChildren, useContext } from 'react';
import { ModalContext } from '../../contexts/ModalContext';
import { t } from 'i18next';

type BaseModalProps = {
  title?: string;
};

export default function ModalWrapper({ title, children }: PropsWithChildren<BaseModalProps>) {
  const { handleModal } = useContext(ModalContext);

  return (
    <div className="relative flex flex-col justify-center items-center gap-8 w-full py-6 p-4 bg-[#131313] rounded-lg text-white md:-translate-x-[120px]">
      {title && <h1 className="text-2xl font-extrabold">{title}</h1>}
      <div className="flex flex-col justify-center items-center gap-2 px-4">{children}</div>
      <footer className="flex justify-around w-full gap-2">
        {/* <a
              className="w-full flex justify-center items-center h-[3.5rem] mx-auto rounded-xl border border-N1 text-N2 text-lg bg-gradient-to-r from-[#473CBC] to-[#202E67]"
              href="https://tosto.re/soundpill1"
            >
              {t("web.openApp")}
            </a> */}
        {/* <button className="font-semibold w-[250px] h-[65px] max-sm:w-[143px] max-sm:h-[43px] rounded-lg bg-gradient-to-r from-[#63ACED] to-[#2E239D]"> */}
        <button onClick={() => handleModal()} className="font-semibold w-full py-3 rounded-lg bg-N7">
          {t('successModal.confirm')}
        </button>
      </footer>
    </div>
  );
}
