import { useState, useEffect } from 'react';
import { getItem, LocalLanguage } from '../../utils/localStorage';

export default function MarketingInformationPage() {
  const [langauge, setLanguage] = useState('');

  useEffect(() => {
    const lan = getItem(LocalLanguage);
    setLanguage(lan);
  });
  return (
    <main className="w-full h-full overflow-y-auto no-scrolbar bg-N0">
      {langauge === 'en' ? (
        <>
          <p>
            <strong>Consent to receive marketing information</strong>
          </p>
          <p>
            The user's prior consent to receive advertising information in accordance with relevant laws and
            regulations, such as the Personal Information Protection Act and the Act on Promotion of Information and
            Communications Network Utilization and Information Protection, etc., in the SoundPill service operated by
            Digital Nutrition Corp. (hereinafter referred to as the “Company”) is receiving. Pursuant to Article 22,
            Paragraph 5 of the Personal Information Protection Act, you can use the service without entering optional
            information.
          </p>
          <p>
            <strong>1. Use in marketing and advertising</strong>
          </p>
          <p>- Develop new features and provide customized services</p>
          <p>- Sending newsletters, information on new features</p>
          <p>- Providing advertising information such as events such as discounts and coupons</p>
          <p> </p>
          <p>
            <strong>2. Provision of marketing information</strong>
          </p>
          <p>
            In the process of operating the service, the company may provide various information to members through push
            notifications, email, SMS, and Kakao Friend Talk. However, in addition to advertising information,
            informational content that is mandatory is provided regardless of whether you agree to receive it or not.
          </p>
          <p>
            <strong> </strong>
          </p>
          <p>
            <strong>3. Consent and withdrawal of receipt</strong>
          </p>
          <p>
            Even after agreeing to receive the service, you may withdraw your consent at will. Even if you do not agree
            to receive the service, you may use the basic services provided by the company, but you may not receive
            marketing information provided by the company. If the customer wishes to withdraw this consent, he or she
            may request withdrawal of consent through the notification settings in the app or through the customer
            center. Additionally, if you wish to newly consent to receiving marketing information in the future, you can
            do so in the notification settings within the app.
          </p>
          <p> </p>
          <p>- Effective date: September 26, 2023</p>
        </>
      ) : (
        <>
          {' '}
          <p>
            <strong>마케팅정보 수신동의</strong>
          </p>
          <p>
            (주)디지털뉴트리션 (이하 “회사”)가 운영하는 사운드필(SoundPill)서비스에서 개인정보보호법 및 정보통신망
            이용촉진 정보보호 등에 관한 법률 등 관계법령에 따라 광고성 정보를 전송하기 위해 이용자의 사전 수신 동의를
            받고 있습니다. 개인정보보호법 제22조 제5항에 의해 선택정보 사항에 대해서는 기재하지 않아도 서비스를 이용하실
            수 있습니다. 
          </p>
          <p>​</p>
          <p>1. 마케팅 및 광고에의 활용</p>
          <p>
            - 신규 기능 개발 및 맞춤 서비스 제공
            <br />- 뉴스레터 발송, 새로운 기능의 안내
            <br />- 할인 및 쿠폰 등 이벤트 등의 광고성 정보 제공
          </p>
          <p> </p>
          <p>2. 마케팅 정보 제공</p>
          <p>
            회사는 서비스를 운용하는 과정에서 각종 정보를 푸시알림, 이메일, SMS 및 카카오 친구톡 등의 방법으로 회원에게
            제공할 수 있습니다. 단, 광고성 정보 이외에 의무적으로 안내되어야 하는 정보성 내용은 수신동의 여부와 무관하게
            제공합니다.
          </p>
          <p> </p>
          <p>3. 수신 동의 및 철회</p>
          <p>
            수신 동의 이후에도 의사에 따라 동의를 철회할 수 있으며, 수신을 동의하지 않아도 회사에서 제공하는 기본적인
            서비스를 이용할 수 있으나 회사가 제공하는 마케팅 정보를 수신하지 못할 수 있습니다. 고객이 본 수신 동의를
            철회하고자 할 경우 앱내 알림 설정 또는 고객센터를 통하여 수신 동의 철회를 요청할 수 있습니다. 또한 향후
            마케팅정보 수신에 새롭게 동의하고자 할 경우 앱내 알림 설정에서 동의하실 수 있습니다.
          </p>
          <p>
            <br />
            <br />
            <br /> 
          </p>
          <p>- 시행일자: 2023년 09월 26일</p>
        </>
      )}
    </main>
  );
}
