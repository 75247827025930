import { Navigate, Route, Routes } from 'react-router-dom';
import AppRoute from './routes/AppRoute';
import AuthRoute from './routes/AuthRoute';
import PaymentRoute from './routes/PaymentRoute';
import { AISurveyRoute } from './routes/AISurveyRoute';
import PromotionRoute from './routes/PromotionRoute';
import CategoryRoot from './routes/CategoryRoute';

//import 'babel-polyfill'

function App() {
  return (
    <Routes>
      <Route path="/app/*" element={<AppRoute />} />
      <Route path="/category/*" element={<CategoryRoot />} />
      <Route path={'/auth/*'} element={<AuthRoute />} />
      <Route path={'/payment/*'} element={<PaymentRoute />} />
      <Route path="ai-survey/*" element={<AISurveyRoute />} />
      <Route path="promotion/*" element={<PromotionRoute />} />
      <Route path={'*'} element={<Navigate to="/app/*" />} />
    </Routes>
  );
}

export default App;
