import { MouseEventHandler, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthStateContext } from '../../contexts/AuthContext';
import { CollaborationContext } from '../../contexts/CollaborationContext';
import { SurveySlider } from './SurveySlider';
import { AIRecommendedTracks, AISurveyResultResponse, Track } from '../../types';
import { ModalContext } from '../../contexts/ModalContext';
import { CouponNotification } from './CouponNotification';
import { AxiosResponse } from 'axios';
import AuthAxios from '../../axios';
import { useTranslation } from 'react-i18next';

export const SoundRecommendations = () => {
  /** Initialization */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state: data, pathname }: { state: AISurveyResultResponse; pathname: string } = useLocation();

  const { isAuthentication, user } = useContext(AuthStateContext);
  const {
    state: collaborationState,
    setState: setCollaborationState,
    autoUseFreeCoupon,
  } = useContext(CollaborationContext);
  const { handleModal } = useContext(ModalContext);

  const [tracks, setTracks] = useState<AIRecommendedTracks[]>([]);
  const [isSurveyDone, setIsSurveyDone] = useState(false);

  console.log('survey data: ', data);

  useEffect(() => {
    async function fetchRecommendedTracks() {
      const response: AxiosResponse<AISurveyResultResponse> = await AuthAxios.post(
        `${process.env.REACT_APP_ENDPOINT}/web/survey/result`
      );
      console.log(response);
      setTracks(response.data.tracks);
    }
    if (!data) {
      fetchRecommendedTracks();
    } else {
      setTracks(data.tracks);
      setIsSurveyDone(true);
    }
  }, [data]);

  useEffect(() => {
    async function applyFreeCouponToDBLifeUser() {
      if (!user) {
        return;
      }

      if (!collaborationState.couponAutoUsed) {
        const response = await autoUseFreeCoupon(user);
        if (response && !response.error) {
          setCollaborationState({
            ...collaborationState,
            couponAutoUsed: true,
          });
          handleModal(
            <CouponNotification user={user} usedCouponName={response.couponName} usedCouponCode={response.couponCode} />
          );
        }
      }
    }
    applyFreeCouponToDBLifeUser();
  }, [user]);

  /** Handlers */
  const handleGoNextButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    if (!isAuthentication) {
      return sendUserToSignup();
    }

    setCollaborationState({
      ...collaborationState,
      isSurveyFinished: true,
    });
    return navigate('/app/home');
  };

  const sendUserToSignup = () => {
    if (!data) {
      setCollaborationState({
        ...collaborationState,
        collaboratorCode: 'DBLIFE',
        isSurveyFinished: true,
      });
    } else {
      setCollaborationState({
        ...collaborationState,
        collaboratorCode: 'DBLIFE',
        isSurveyFinished: false,
        surveyData: {
          surveyResult: data,
          referer: pathname,
        },
      });
    }

    return navigate('/ai-survey/signin', {
      state: {
        referer: pathname,
      },
    });
  };

  return (
    <div className="flex flex-col justify-center w-full h-full bg-gray-400">
      <div className="flex relative flex-col align-center w-full md:w-[800px] h-full m-auto bg-white">
        {tracks && (
          <SurveySlider tracks={tracks} handleUnauthenticatedUser={sendUserToSignup} isSurveyDone={isSurveyDone} />
        )}
        <button
          style={{ background: '#2166eb', color: '#f4f4f4' }}
          className="w-[85%] md:w-[55%] h-[3.125rem] mx-auto text-xl font-bold rounded-lg my-5 md:mt-10"
          onClick={handleGoNextButtonClick}
        >
          {t('survey.moreSounds')}
        </button>
        {/*
        Modals for popups
        <button
          style={{ background: "#2166eb", color: "#f4f4f4" }}
          className="w-[85%] md:w-[55%] h-[3.125rem] ml-6 text-xl font-bold rounded-lg mt-[70px] md:mt-10"
          onClick={popupsHandler}
        >
          Button for popups
        </button> */}
      </div>
    </div>
  );
};
