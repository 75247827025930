import { Link, NavLink } from 'react-router-dom';
import {
  BrainSvg,
  CancelSVG,
  GiftSvg,
  GroupSvg,
  HomeSvg,
  LeafSvg,
  LOGOSVG,
  MgnfySvg,
  MgSvg,
  MiracleSvg,
  MoonSvg,
  SetSvg,
  WeightSvg,
} from '../../assets';
import OpenAppBtn from '../common/OpenAppBtn';
import IconButton from '../IconButton';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const MobileNavigate = ({ onClickMenu }: { onClickMenu: () => void }) => {
  const { t } = useTranslation();
  return (
    <nav className="flex flex-col w-full h-full gap-[34px] p-[20px] bg-N9">
      <div className="flex justify-between w-full">
        <LOGOSVG className="w-[93px]" />
        <div className="flex justify-between gap-4">
          <OpenAppBtn />
          <IconButton size="sm" onClick={onClickMenu}>
            <CancelSVG />
          </IconButton>
        </div>
      </div>
      <div className="flex flex-col w-full h-full gap-[34px] overflow-y-scroll no-scrollbar">
        <NavItem
          icon={<HomeSvg />}
          name={t('navigator.home')} // Translate "Home" text
          path={'/app/home'}
          onClickMenu={onClickMenu}
        />
        <NavItem
          icon={<MgnfySvg path="white" />}
          name={t('navigator.search')} // Translate "Search" text
          path={'/app/search'}
          onClickMenu={onClickMenu}
        />
        <NavItem
          icon={<GroupSvg />}
          name={t('navigator.profile')} // Translate "Profile" text
          path={'/app/profile'}
          onClickMenu={onClickMenu}
        />
        <NavItem
          icon={<MiracleSvg />}
          name={t('navigator.minit_miracle')} // Translate "1 Minute Miracle" text
          path={'/app/category/minit_miracle'}
          onClickMenu={onClickMenu}
        />
        <NavItem
          icon={<MoonSvg />}
          name={t('navigator.sleep')} // Translate "Sleep" text
          path={'/app/category/sleep'}
          onClickMenu={onClickMenu}
        />
        <NavItem
          icon={<WeightSvg />}
          name={t('navigator.diet')} // Translate "Diet" text
          path={'/app/category/diet'}
          onClickMenu={onClickMenu}
        />
        <NavItem
          icon={<BrainSvg />}
          name={t('navigator.concentration')} // Translate "Concentration" text
          path={'/app/category/concentration'}
          onClickMenu={onClickMenu}
        />
        <NavItem
          icon={<LeafSvg />}
          name={t('navigator.stress')} // Translate "Stress Relief" text
          path={'/app/category/stress'}
          onClickMenu={onClickMenu}
        />
        <NavItem
          icon={<MgSvg />}
          name={t('navigator.experience')} // Translate "Experience" text
          path={'/app/category/experience'}
          onClickMenu={onClickMenu}
        />
        <NavItem
          icon={<SetSvg />}
          name={t('navigator.setting')} // Translate "Settings" text
          path={'/app/setting'}
          onClickMenu={onClickMenu}
        />
        <div>
          <NavLink
            onClick={onClickMenu}
            to={'/app/gift'}
            className={({ isActive, isPending }) =>
              isPending
                ? ''
                : isActive
                  ? 'w-full border border-N5 bg-N5 rounded-[20px] h-[56px] text-N9 font-bold flex items-center justify-center gap-[5.7px]'
                  : 'w-full border border-N5 bg-transparent rounded-[20px] h-[56px] text-N3 font-bold flex items-center justify-center gap-[5.7px]'
            }
          >
            <div className="flex items-center gap-[12px] font-semibold text-lg">
              <GiftSvg />
              <span>{t('navigator.gift')}</span>
            </div>
          </NavLink>
        </div>

        <div className="flex items-end justify-around w-full h-full text-N6">
          <Link onClick={onClickMenu} to="/app/terms-of-use">
            {t('navigator.useTerm')} {/* Translate "Terms of Use" text */}
          </Link>
          <Link onClick={onClickMenu} to="/app/privacy-policy">
            {t('navigator.privatePolicy')}
            {/* Translate "Privacy Policy" text */}
          </Link>
        </div>
      </div>
    </nav>
  );
};

function NavItem({
  icon,
  name,
  path,
  onClickMenu,
}: {
  icon: React.ReactElement;
  name: string;
  path: string;
  onClickMenu: () => void;
}) {
  return (
    <NavLink
      onClick={onClickMenu}
      to={path}
      className={({ isActive, isPending }) => (isPending ? '' : isActive ? 'text-N0' : 'text-N5')}
    >
      <div className="flex items-center gap-[12px] font-semibold text-lg">
        {icon}
        <span>{name}</span>
      </div>
    </NavLink>
  );
}

export default MobileNavigate;
