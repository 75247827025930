import { Route, Routes } from 'react-router-dom';
import CouponPage from '../pages/Promotion/CouponPage';

export default function PromotionRoute() {
  return (
    <Routes>
      <Route path="/coupon" element={<CouponPage />} />
    </Routes>
  );
}
