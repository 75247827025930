import { Route, Routes } from 'react-router-dom';
import DBSigninPage from '../pages/DBInsurance/DBSigninPage';
import { DBSurveyStart } from '../pages/DBInsurance/DBSurveyStart';
import { ResultAfterSurvey } from '../pages/DBInsurance/ResultAfterSurvey';
import { SoundRecommendations } from '../pages/DBInsurance/SoundRecommendations';
import { SurveyQuestions } from '../pages/DBInsurance/SurveyQuestions';

export const AISurveyRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<DBSurveyStart />} />
      <Route path="survey-questions" element={<SurveyQuestions />} />
      <Route path="survey-result" element={<ResultAfterSurvey />} />
      <Route path="recommended-sound" element={<SoundRecommendations />} />
      <Route path="signin" element={<DBSigninPage />} />
    </Routes>
  );
};
