import queryString from 'query-string';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthAxios from '../../axios';
import { ModalContext } from '../../contexts/ModalContext';

export default function ResetPasswordPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleModal } = useContext(ModalContext);
  const [password, setPassword] = useState('');
  const [result, setResult] = useState(true);
  const location = useLocation();
  const query = queryString.parse(location.search);
  const { confirmToken } = query;

  const handleResetPassword = () => {
    // 변경 api
    // confirmToken password 같이 보낸다.

    AuthAxios.post(`/web/auth/reset-password`, {
      password: password,
      confirmToken: confirmToken,
    })
      .then(() => {
        setResult(false);
      })
      .catch((error) => {
        handleModal(
          <div className="w-[300] bg-N10 flex flex-col items-center rounded-[22px] text-N0 p-2 px-4">
            <div>{error.response.data.message ?? ''}</div>
            <button
              className="w-full bg-N5 text-N10 rounded-[22px] text-base font-semibold"
              onClick={() => handleModal()}
            >
              확인
            </button>
          </div>
        );
      });
  };
  const onClickComplite = () => {
    navigate('/auth');
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="flex flex-col items-center">
        <header className="text-[22px] font-medium text-N0 mb-4">{t('passwordResetPage.passwordReset')}</header>
        {result ? (
          <>
            <div className="text-base font-medium text-center text-N0">{t('passwordResetPage.passwordRequest')}</div>
            <main className="flex flex-col gap-[18px] mt-9">
              <input
                type="password"
                name="dn_reset_password"
                placeholder={t('passwordResetPage.newPassword')}
                className="w-[288px] h-[48px] rounded-lg p-3 text-N3 text-base placeholder:text-N3"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
              <button
                onClick={handleResetPassword}
                disabled={password.trim().length < 6}
                className="w-[288px] h-[48px] rounded-lg text-N2 text-base font-medium bg-gradient-to-b from-[#473CBC] to-[#202E67]"
              >
                {t('passwordResetPage.register')}
              </button>
            </main>
          </>
        ) : (
          <>
            <div className="text-base font-medium text-N0">{t('passwordResetPage.passwordChanged')}</div>
            {/* <div className="text-base font-medium text-N0">
              이제 새로운 비밀번호로 SoundPill 앱에
            </div> */}
            <div className="text-base font-medium text-center text-N0">{t('passwordResetPage.loginInstruction')}</div>
            <main className="flex flex-col gap-[18px] mt-9">
              <button
                onClick={onClickComplite}
                className="w-[288px] h-[48px] rounded-lg text-N2 text-base font-medium bg-gradient-to-b from-[#473CBC] to-[#202E67]"
              >
                {t('passwordResetPage.openSoundPillApp')}
              </button>
            </main>
          </>
        )}
      </div>
    </div>
  );
}
