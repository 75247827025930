import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Tick from '../../assets/images/tick.png';
import AuthAxios from '../../axios';
import { AISurveyResultResponse } from '../../types';
import { useTranslation } from 'react-i18next';

const MAX_WIDTH = 300;

function usePredict(payload: string) {
  const { t } = useTranslation();
  const [results, setResults] = useState<AISurveyResultResponse>();
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response: AxiosResponse<AISurveyResultResponse> = await AuthAxios.post(
          `${process.env.REACT_APP_ENDPOINT}/web/survey/result`,
          { data: JSON.parse(payload) }
        );

        const { surveyResult } = response.data;
        console.log('response.data::=>>>>>', response.data);

        const reversed = surveyResult.map((r) => ({
          ...r,
          prob: 1 - r.prob,
        }));
        const scaled = reversed.map((r) => ({
          ...r,
          prob: r.prob + 0.3,
        }));
        const normalized = scaled.map((r) => ({
          ...r,
          prob: r.prob / 1.3,
        }));

        setResults({
          ...response.data,
          surveyResult: normalized,
        });

        setLoading(false);
      } catch (error) {
        setError(error);
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [payload]);

  return { results, error, loading };
}

export const ResultAfterSurvey = () => {
  /** Initialization */
  const navigate = useNavigate();
  const {
    state: { data: payload },
  } = useLocation();
  // 테스트 용
  // const payload =
  //   '{"Gender":0,"AgeGroup":3,"DiagnosedConditions":3,"HighFatFoodIntakeDays":0,"TimeToFallAsleep":1,"AnxietyFrequency":0,"FamilyHistoryHypertension":1,"MemoryIssuesFrequency":1,"DailyDiscomfortFrequency":0,"SkinIssuesFrequency":1}';
  const { results, error, loading } = usePredict(payload);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="flex flex-col justify-center items-center w-full h-full bg-gray-600">
      <div className="max-w-[600px] h-full px-4 py-8 flex flex-col justify-center align-center bg-white">
        <div className="w-full h-full pb-8 flex flex-col gap-8 overflow-y-scroll no-scrollbar">
          <div className="flex flex-col justify-center items-center">
            <h1 className="w-full font-bold bg-[191919] text-2xl text-center">현재 상태</h1>
            <div className="relative w-full mt-4 border-[1px] px-6 py-8 rounded-md flex flex-col gap-8">
              <div className="flex flex-col relative">
                <img
                  src={Tick}
                  alt="report-img"
                  className="opacity-20 absolute w-[9.375rem] h-[9.375rem] top-[50%] right-0 -translate-y-[50%]"
                />
                <div className="py-6 flex flex-col gap-4">
                  {results?.surveyResult.map((item) => (
                    <div key={item.category} className="w-full flex items-center">
                      <h1 className="font-bold mr-2">{item.rank}</h1>
                      <div
                        className="h-8 rounded-2xl py-1 overflow-visible"
                        style={{
                          width: `${item.prob * MAX_WIDTH}px`,
                          background: `${item.rank === 'A' ? '#95B9FF' : item.rank === 'B' ? '#cfd3db' : '#FFAE9B'}`,
                        }}
                      >
                        <h1 className="absolute ml-3 text-[#191919] font-medium">
                          {t(`survey.${item.category}.name`)}
                        </h1>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mx-4" style={{ border: '1px solid #e5e8ee' }}></div>

              <div>
                <h1 className="text w-full text-[#191919] font-semibold leading-8">
                  {results && results.category && t(`survey.${results.category}.comment`)}
                </h1>
              </div>
            </div>
          </div>
          <div className="px-6">
            {/* <div className="hidden md:flex w-[75%] mx-auto" style={{ border: '1px solid #e5e8ee' }}></div> */}
            <h1 className="text-N11 text-xl font-semibold text-center">종합결과</h1>
            <img src={Tick} alt="" className="hidden w-[5.9375rem] h-[5.9375rem] mx-auto" />
            <div className="w-full">
              {results?.surveyResult.map((item) => (
                <h1 key={item.category} className="w-full mx-auto mt-2 text-[#191919] text-md leading-8">
                  {/* {summaryMessages[`${item.category}_${item.level}`]} */}
                  {t(`survey.${item.category}.rank.${item.rank}`)}
                </h1>
              ))}
            </div>
          </div>
        </div>
        <div className="shrink-0 pt-4 pb-8">
          <button
            className="w-full h-full py-4 bg-[#2166eb] rounded-lg text-xl text-white font-bold"
            onClick={() => navigate('/ai-survey/recommended-sound', { state: results })}
          >
            사운드 추천 받기
          </button>
        </div>
      </div>
    </div>
  );
};
