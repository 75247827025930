import { FacebookLogin as MetaLogin } from '../../assets';

import AuthAxios from '../../axios';
import { useNavigate } from 'react-router-dom';
import { getItem, LocalLanguage, setItem, USERINFO, USERTOKEN } from '../../utils/localStorage';
import { useContext } from 'react';
import { AuthStateContext } from '../../contexts/AuthContext';
import { ModalContext } from '../../contexts/ModalContext';
declare const FB: any;

export default function FacebookLoginBtn({
  afterSocialLoginSuccess,
}: {
  afterSocialLoginSuccess: ({ type, socialId, email }: { type: string; socialId: string; email: string }) => void;
}) {
  const navigate = useNavigate();
  const { handleModal } = useContext(ModalContext);
  const { setLoginSucssess } = useContext(AuthStateContext);
  const handleFacebookLogin = (response: any) => {
    afterSocialLoginSuccess({
      type: 'facebook',
      socialId: response.id,
      email: response.email,
    });
    // AuthAxios.post(`/web/auth/social`, {
    //   type: "facebook",
    //   socialId: response.id,
    //   mail: response.email,
    // })
    //   .then((resolve) => {
    //     const { user, accessToken, refreshToken } = resolve.data;

    //     setItem(USERINFO, user);
    //     setItem(USERTOKEN, { accessToken, refreshToken });
    //     setLoginSucssess(user);
    //     if (user.termsAgreed) {
    //       // navigate("/app/home", { replace: true });
    //       if (user.gender && user.birthday) {
    //         navigate("/app/home", { replace: true });
    //       } else {
    //         navigate("/auth/basicInfo", { replace: true });
    //       }
    //     } else {
    //       navigate("/auth/agrement", { replace: true });
    //     }
    //   })
    //   .catch((error) => {
    //     navigate("/auth", { replace: true });
    //     handleModal(
    //       <div className="w-[300] bg-N10 flex flex-col items-center rounded-[22px] text-N0 p-2 px-4">
    //         <div>{error.response.data.message ?? ""}</div>
    //         <button
    //           className="w-full bg-N5 text-N10 rounded-[22px] text-base font-semibold"
    //           onClick={() => handleModal()}
    //         >
    //           확인
    //         </button>
    //       </div>
    //     );
    //   });
  };
  const onClickLogin = () => {
    FB.login(
      function (response: any) {
        // console.log(response);
        if (response.authResponse) {
          // console.log("Welcome!  Fetching your information.... ");
          FB.api('/me?fields=name,email', function (response: any) {
            // console.log("response:: ", response);
            // console.log("email:", response.email);
            // console.log("Good to see you, " + response + ".");
            handleFacebookLogin(response);
          });
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      { scope: 'public_profile,email' }
    );
  };
  return (
    <button className="flex items-center justify-center w-12 h-12 bg-blue-600 rounded-lg" onClick={onClickLogin}>
      <MetaLogin />
    </button>
  );
}
