import React from 'react';
import './CustomRadioButton.css';

interface CustomRadioButtonProps {
  id: string;
  name: string;
  value: number;
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  multiple: boolean;
}

const CustomRadioButton: React.FC<CustomRadioButtonProps> = ({
  id,
  name,
  value,
  label,
  checked,
  onChange,
  multiple,
}) => {
  return (
    <div className="flex items-center my-1">
      <input
        type={multiple ? 'checkbox' : 'radio'}
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className="hidden"
      />
      <label htmlFor={id} className="relative pl-8 cursor-pointer text-lg">
        <span className="custom-radio"></span>
        {label}
      </label>
    </div>
  );
};

export default CustomRadioButton;
