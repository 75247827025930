export function setItem(name: string, value: any) {
  localStorage.setItem(name, JSON.stringify(value));
}
export function getItem(name: string) {
  const localData = localStorage.getItem(name);
  if (localData) return JSON.parse(localData);
  return null;
}
export function removeItem(name: string) {
  localStorage.removeItem(name);
  return null;
}
export function getAccecssToken(name: string) {
  const localData = localStorage.getItem(name);
  if (localData) {
    const token = JSON.parse(localData);
    return token.accessToken as string;
  }
  return null;
}

export function getRefreshToken(name: string) {
  const localData = localStorage.getItem(name);
  if (localData) {
    const token = JSON.parse(localData);
    return token.refreshToken as string;
  }
  return null;
}

export function getToken(name: string) {
  const localData = localStorage.getItem(name);
  if (localData) {
    const token = JSON.parse(localData);
    return token;
  }
  return null;
}

export interface RESPONSETYPE {
  id: number;
  accessToken: string;
  refreshToken: string;
}
export const USERTOKEN = 'app.soundpill.user.nid.token';
export const USERINFO = 'app.soundpill.user.nid.info';
export const LocalLanguage = 'app.soundpill.ln.crnt';
export const SYSTEMTHEMESAVEINFO = 'app.soundpill.system.theme.nid';
