import { useContext } from 'react';
import { MusicPlayerStateContext } from '../../contexts/MusicContext';

interface VolumeInputProps {
  volume: number;
  onVolumeChange: (volume: number) => void;
}
export default function VolumeInput({ volume, onVolumeChange }: VolumeInputProps) {
  return (
    <input
      aria-label="volume"
      name="volume"
      type="range"
      min={0}
      max={1}
      step={0.05}
      value={volume}
      onChange={(e) => {
        onVolumeChange(e.currentTarget.valueAsNumber);
      }}
      className="w-[100px] m-0 h-[6px] rounded-full accent-N1 bg-N8 appearance-none cursor-pointer border border-N1"
    />
  );
}
