import { useContext } from 'react';
import { LockIconSVG } from '../assets';
import { AuthStateContext } from '../contexts/AuthContext';
import { Track } from '../types';
import formatDurationDisplay from '../utils/formatDurationDisplay';
import { transLn } from '../utils/transLn';
import FallBackImg from '../assets/images/fallback.jpg';

interface ICustomTrackCompV2Props extends React.ComponentPropsWithoutRef<'button'> {
  track: Track;
  durationVisible?: boolean;
}
export function CustomTrackCompV2(props: ICustomTrackCompV2Props) {
  const { track, className, durationVisible, ...restProps } = props;
  const durationDisplay = formatDurationDisplay(track.duration);

  const { user } = useContext(AuthStateContext);
  return (
    <button className={`relative overflow-hidden drop-shadow-lg ${className}`} {...restProps}>
      <img
        src={`${process.env.REACT_APP_IMG_ENDPOINT}/${track.thumbnail}`}
        alt="art-work"
        className="absolute inset-0 z-0 object-cover w-full h-full"
        onError={(event) => {
          event.currentTarget.src = FallBackImg;
        }}
        loading="lazy"
      />
      {!track.isFree && !user?.currentSubscription && (
        <span className="absolute top-4 left-4 w-[24px] h-[24px] md:w-[30px] md:h-[30px] rounded-full bg-[#252835] flex justify-center items-center">
          <LockIconSVG className="max-md:w-[10px]" />
        </span>
      )}
      <div className="absolute inset-0 z-10 bg-gradient-to-t from-black to-transparent"></div>
      <div className="absolute bottom-0 left-0 z-20 w-full p-2">
        <div className="flex flex-col items-start w-full gap-1 ">
          <span className="w-full font-bold text-left truncate text-N1 text-ellipsis">{transLn(track.title)}</span>
          <div className="flex justify-between w-full gap-5">
            <span className="text-sm truncate text-N2">{transLn(track.category)}</span>
            {durationVisible && <span className="text-sm text-N2">{durationDisplay}</span>}
          </div>
        </div>
      </div>
    </button>
  );
}
