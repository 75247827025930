export const MOB_HEADER_Z_IDX = 1_000;
export const EDITOR_Z_IDX = 9_000;
export const MOBILE_NAV_IDX = 9_000;
export const MODAL_IDX = 10_000;
export const MUSIC_PLAYER_IDX = 1_000;
export const PLAYLIST_Z_IDX = 8_000;

export const API_URL = 'https://hh83bcvb2g.execute-api.ap-northeast-2.amazonaws.com';

export const SURVEY_API = 'https://dev-be.soundpill.app';
