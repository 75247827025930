import { useContext } from 'react';
import AuthAxios from '../../axios';
import { Login } from '../../types';
import { CollaborationContext } from '../../contexts/CollaborationContext';
import { useNavigate } from 'react-router-dom';
import { AuthStateContext } from '../../contexts/AuthContext';
import { ModalContext } from '../../contexts/ModalContext';
import { LocalLanguage, USERINFO, USERTOKEN, getItem, setItem } from '../../utils/localStorage';
import FacebookLoginBtn from './FacebookLoginBtn';
import AppleLoginBtn from './AppleLoginBtn';
import GoogleLoginBtn from './GoogleLoginBtn';
import NaverLoginBtn from './NaverLoginBtn';
import KakaoLoginBtn from './KakaoLoginBtn';

export default function SocialLoginButtons() {
  const lan = getItem(LocalLanguage);
  const navigate = useNavigate();
  const { setLoginSucssess } = useContext(AuthStateContext);
  const {
    state: collaborationState,
    linkUserToCollaborateUser,
    autoUseRegisteredOrderedCoupon,
  } = useContext(CollaborationContext);
  const { handleModal } = useContext(ModalContext);

  const afterSocialLoginSuccess = ({ type, socialId, email }: { type: string; socialId: string; email: string }) => {
    AuthAxios.post<Login>('/web/auth/social', { type, socialId, email })
      .then(async (resolve) => {
        // 살루스케어 회원가입
        console.log('state: ', collaborationState);
        if (collaborationState.collaboratorCode === 'SALUSCARE') {
          await linkUserToCollaborateUser(resolve.data.user);

          await autoUseRegisteredOrderedCoupon(resolve.data.user);
        }

        // console.log(resolve.data.user);

        return resolve;
      })
      .then((resolve) => {
        const { user, accessToken, refreshToken, subscriptionInfo } = resolve.data;

        //subscriptionInfo 나중에 로그인 후 구독권 만료에 대한 고지용 팝업 .
        setItem(USERINFO, user);
        setItem(USERTOKEN, { accessToken, refreshToken });
        setLoginSucssess(user);

        return user;
      })
      .then((user) => {
        if (user.termsAgreed) {
          if (user.gender && user.birthday) {
            return navigate('/app/home');
          } else {
            return navigate('/auth/basicInfo');
          }
        }
        return navigate('/auth/agrement');
      })
      .catch((error) => {
        navigate('/auth', { replace: true });
        // console.log(error.response.data.message ?? "");
        let message = error.response.data.message;
        if (error.response.data.message === '재가입은 탈퇴 3개월 이후부터 가능합니다.' && lan === 'en') {
          message = 'You can re-sign up 3 months after deleting your account.';
        }
        handleModal(
          <div className="w-[300] bg-N10 flex flex-col items-center rounded-[22px] text-N0 p-2 px-4">
            <div>{message ?? ''}</div>
            <button
              className="w-full bg-N5 text-N10 rounded-[22px] text-base font-semibold"
              onClick={() => handleModal()}
            >
              {lan === 'en' ? 'ok' : '확인'}
            </button>
          </div>
        );
      });
  };

  return (
    <>
      <FacebookLoginBtn afterSocialLoginSuccess={afterSocialLoginSuccess} />
      <AppleLoginBtn afterSocialLoginSuccess={afterSocialLoginSuccess} />
      <GoogleLoginBtn afterSocialLoginSuccess={afterSocialLoginSuccess} />
      <NaverLoginBtn afterSocialLoginSuccess={afterSocialLoginSuccess} />
      <KakaoLoginBtn afterSocialLoginSuccess={afterSocialLoginSuccess} />
    </>
  );
}
