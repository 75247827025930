import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.css';
import Slider from 'react-slick';
import { useCallback, useEffect, useRef } from 'react';

interface TimePickerProps {
  setHourIndex: Function;
  setMinuteIndex: Function;
}
export default function TimePicker({ setHourIndex, setMinuteIndex }: TimePickerProps) {
  const slider1 = useRef<any>(null);
  const slider2 = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const hoursList = Array.from({ length: 13 }, (v, k) => k);
  const minutesList = Array.from({ length: 60 }, (v, k) => k);
  const settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    speed: 100,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    arrows: false,
    adaptiveHeight: false,
    afterChange: (i: number) => {
      setHourIndex(i - 1);
    },
  };
  const settings2 = {
    ...settings,
    afterChange: (i: number) => {
      setMinuteIndex(i - 1);
    },
  };

  const scroll = useCallback((y: number, slider: React.RefObject<any>) => {
    if (y > 0) {
      return slider.current.slickNext();
    } else {
      return slider.current.slickPrev();
    }
  }, []);

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (e.target instanceof Element) {
        if (e.target.closest('.slider1') && slider1.current) {
          scroll(e.deltaY, slider1);
        } else if (e.target.closest('.slider2') && slider2.current) {
          scroll(e.deltaY, slider2);
        }
      }
    };

    const containerElement = containerRef.current;
    if (containerElement) {
      containerElement.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (containerElement) {
        containerElement.removeEventListener('wheel', handleWheel);
      }
    };
  }, [scroll]);

  return (
    <>
      <div ref={containerRef} className="flex w-[300px] h-[180px] p-2 justify-center overflow-hidden  m-auto relative">
        <Slider ref={slider1} {...settings} className="slider-entity hours slider1">
          {hoursList.map((h, index) => {
            return <div key={h}>{h < 10 ? '0' + h : h}</div>;
          })}
        </Slider>
        <Slider ref={slider2} {...settings2} className="slider-entity minutes slider2">
          {minutesList.map((h, index) => {
            return <div key={h}>{h < 10 ? '0' + h : h}</div>;
          })}
        </Slider>
        <div className="mask">
          <div className="mask-item"></div>
          <div className="mask-item"></div>
        </div>
      </div>
    </>
  );
}
