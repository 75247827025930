// export default function (date: Date) {
//   const getYear = date.getFullYear();
//   const getMonth = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth()는 0부터 시작하기 때문에 +1이 필요합니다.
//   const getDay = date.getDate().toString().padStart(2, "0");

import { getItem, LocalLanguage } from './localStorage';

//   return `${getYear}.${getMonth}.${getDay}`;
// }

export const formatDateDisplay = function (date: Date) {
  const getYear = date.getFullYear();
  const getMonth = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth()는 0부터 시작하기 때문에 +1이 필요합니다.
  const getDay = date.getDate().toString().padStart(2, '0');

  return `${getYear}.${getMonth}.${getDay}`;
};
/** yyyy-mm-dd */
export const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // +1 because months are 0-based in JS.
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};

/** string date convert  yyyy-mm-dd */
export const formatDateToYYYYMMDD = (_date: string) => {
  const date = new Date(_date);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // +1 because months are 0-based in JS.
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};

/** 00월 00일(day) 00:00 am|pm */
export const formatDateToCustomString = (dateStr: string) => {
  const date = new Date(dateStr);

  // 1. 월, 일을 추출합니다.
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // +1 because months are 0-based in JS.
  const day = ('0' + date.getDate()).slice(-2);

  // 2. 요일을 추출합니다.
  const days = ['일', '월', '화', '수', '목', '금', '토'];
  const dayOfWeek = days[date.getDay()];

  // 3. 시간 및 분을 추출합니다.
  let hours = date.getUTCHours();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // 12am과 12pm 처리
  const minutes = ('0' + date.getUTCMinutes()).slice(-2);
  const lan = getItem(LocalLanguage);
  if (lan === 'en') {
    return `${month}/${day}`;
  }
  return `${month}월 ${day}일`;
};

/** second-> 00시간 00분 */
export function secondsToHoursMinutes(seconds: number): string {
  const lan = getItem(LocalLanguage);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  if (lan === 'en') {
    return `${hours} hours ${minutes} mins`;
  } else {
    return `${hours}시간 ${minutes}분`;
  }
}
