import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  LikeEmptySVG,
  LikeFillSVG,
  PauseSVG,
  PlayListSVG,
  PlaySVG,
  RePlayAllSVG,
  RePlayOneSVG,
  ReplySVG,
  ShuffleSVG,
  SkipBackSVG,
  SkipForwardSVG,
  TimerSVG,
  VolumeOffSVG,
  VolumeOnSVG,
} from '../../assets';

import FallBackImg from '../../assets/images/fallback.jpg';
import { ModalContext } from '../../contexts/ModalContext';
import { MusicPlayerStateContext } from '../../contexts/MusicContext';
import formatDurationDisplay from '../../utils/formatDurationDisplay';
import { transLn } from '../../utils/transLn';
import IconButton from '../IconButton';
import TimerModal from '../modals/TimerModal';
import AudioProgressBar from './AudioProgressBar';
import VolumeInput from './VolumeInput';

export default function AudioPlayer() {
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location;
  const path = state?.path as string;
  const { handleModal } = useContext(ModalContext);
  const {
    audioRef,
    currentPlayMusic,
    isReady,
    isMusicPlaying,
    volume,
    duration,
    currentProgress,
    buffered,
    currentSongIndex,
    playList,
    rePlayState,
    shuffleState,

    handleSetTimer,
    onClickRePlay,
    onClickShuffle,
    onClickLikeBtn,
    togglePlayPause,
    handleMuteUnmute,
    handleVolumeChange,
    onChangeCurrentProgressBar,

    onPrev,
    onNext,
  } = useContext(MusicPlayerStateContext);

  const [isHidden, setIsHidden] = useState(false);
  const durationDisplay = formatDurationDisplay(duration);
  const elapsedDisplay = formatDurationDisplay(currentProgress);

  /** 음원 상세 페이지 이동 */
  const handleMusicDetail = () => {
    if (
      window.location.pathname.includes('/app/home/comment/') ||
      window.location.pathname.includes('/app/home/playlist')
    ) {
      navigate(path ?? '/app/home');
    } else {
      navigate(`/app/player/${currentPlayMusic?.id}`, {
        state: {
          path: window.location.pathname,
        },
      });
    }
  };

  /** 플레이 리스트 페이지 이동. */
  const handleNavigatePlayListPage = () => {
    navigate('/app/home/playlist');
  };
  /** 음원 댓글 페이지 이동. */
  const onClickReply = () => {
    navigate(`/app/home/comment/${currentPlayMusic?.id}`);
  };

  useEffect(() => {
    if (window.location.pathname.includes('/app/player/')) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
  }, [window.location.pathname, isHidden]);

  if (currentPlayMusic === null) return null;

  return (
    <div className={`${isHidden ? 'hidden' : 'visible'} w-full h-[80px]`}>
      <div className="relative flex justify-between md:justify-start md:gap-20 p-2 bg-slate-900/70 text-slate-400 h-[80px]">
        <AudioProgressBar
          duration={duration}
          currentProgress={currentProgress}
          buffered={buffered}
          onChange={(e) => {
            if (!audioRef?.current) return;
            audioRef.current.currentTime = e.currentTarget.valueAsNumber;
            onChangeCurrentProgressBar(e.currentTarget.valueAsNumber);
          }}
        />
        {currentPlayMusic && (
          <div className="flex">
            <div className="flex items-center gap-2 w-fit h-fit mt-1" onClick={handleMusicDetail}>
              <img
                src={`${process.env.REACT_APP_IMG_ENDPOINT}/${currentPlayMusic.thumbnail}`}
                className="w-[3.75rem] h-[3.75rem] rounded-lg object-cover mx-1 "
                aria-label="thumbnail"
                onError={(event) => {
                  event.currentTarget.src = FallBackImg;
                }}
              />
              <div className="flex flex-col gap-1 w-[170px]">
                <p className="font-medium truncate text-N1 text-ellipsis">
                  {transLn(currentPlayMusic.title) ?? 'Select a song'}
                </p>
                <p className="text-N3 text-[14px] truncate">{transLn(currentPlayMusic.category)}</p>
              </div>
            </div>
          </div>
        )}
        <div className="flex items-center gap-3 max-md:hidden">
          <IconButton
            intent="secondary"
            size="sm"
            aria-label={volume === 0 ? 'unmute' : 'mute'}
            onClick={handleMuteUnmute}
          >
            {volume === 0 ? (
              <VolumeOffSVG style={{ width: 20, height: 20 }} className="fill-white" />
            ) : (
              <VolumeOnSVG style={{ width: 20, height: 20 }} className="fill-white" />
            )}
          </IconButton>
          <VolumeInput volume={volume} onVolumeChange={handleVolumeChange} />
        </div>
        <div className="shrink-0 flex items-center justify-between w-[205px] max-md:hidden">
          <IconButton disabled={!isReady} size="sm" onClick={onClickRePlay}>
            {rePlayState === 0 && <RePlayAllSVG fill="grey" width={20} />}
            {rePlayState === 1 && <RePlayOneSVG width={20} />}
            {rePlayState === 2 && <RePlayAllSVG fill="white" width={20} />}
          </IconButton>
          <IconButton disabled={!isReady || currentSongIndex === 0} size="sm" onClick={onPrev}>
            <SkipBackSVG width={20} />
          </IconButton>
          <IconButton
            disabled={!isReady}
            onClick={togglePlayPause}
            className="w-[36px] h-[36px] flex justify-center items-center"
          >
            {!isReady && currentPlayMusic ? (
              <div>loading</div>
            ) : isMusicPlaying ? (
              <PauseSVG width={30} />
            ) : (
              <PlaySVG width={30} />
            )}
          </IconButton>
          <IconButton disabled={!isReady || currentSongIndex === playList.length - 1} onClick={onNext}>
            <SkipForwardSVG width={20} />
          </IconButton>
          <IconButton disabled={!isReady} size="sm" onClick={onClickShuffle}>
            {!shuffleState ? <ShuffleSVG fill="grey" width={20} /> : <ShuffleSVG fill="white" width={20} />}
          </IconButton>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-[20px] max-md:hidden">
            <IconButton disabled={!isReady} size="sm" onClick={onClickReply}>
              <ReplySVG className="fill-white" width={20} />
            </IconButton>
            <IconButton disabled={!isReady} onClick={() => handleModal(<TimerModal handleSetTimer={handleSetTimer} />)}>
              <TimerSVG className="fill-white" width={20} />
            </IconButton>
            <IconButton disabled={!isReady} size="sm" onClick={() => onClickLikeBtn()}>
              {currentPlayMusic && currentPlayMusic.isLiked ? (
                <LikeFillSVG className="fill-white" width={20} />
              ) : (
                <LikeEmptySVG className="fill-white" width={20} />
              )}
            </IconButton>
          </div>
          <span className=" text-right text-N0 max-md:hidden w-[110px]">
            {elapsedDisplay} / {durationDisplay}
          </span>
          <IconButton
            disabled={!isReady}
            size="lg"
            onClick={togglePlayPause}
            className="w-[24px] h-[24px] flex justify-center items-center md:hidden"
          >
            {!isReady && currentPlayMusic ? <div>loading</div> : isMusicPlaying ? <PauseSVG /> : <PlaySVG />}
          </IconButton>
          <IconButton size="sm" onClick={handleNavigatePlayListPage}>
            <PlayListSVG />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
