import { createContext, useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { MODAL_IDX } from '../utils/constant';

type ModalState = {
  modal: boolean;
  modalContent: React.ReactNode;
  handleModal: Function;
};
export const ModalContext = createContext<ModalState>({
  modal: false,
  modalContent: null,
  handleModal: () => {},
});

export const ModalContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>("I'm the Modal Content");

  const handleModal = (content = false) => {
    console.log(modal);
    // setModal((prev) => !prev);
    setModal(!modal);
    if (content) {
      setModalContent(content);
    } else {
      setModalContent('');
    }
  };
  const handlePopState = () => {
    if (modal) {
      setModal(false);
    }
  };

  // 컴포넌트가 마운트 될 때 이벤트 리스너 추가
  useEffect(() => {
    window.addEventListener('popstate', handlePopState);

    // 컴포넌트가 언마운트 될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [modal]); // modal의 상태 변화에 따라 effect 실행

  const value = { modal, modalContent, handleModal };

  return (
    <ModalContext.Provider value={value}>
      <Modal />
      {children}
    </ModalContext.Provider>
  );
};

const Modal = () => {
  const { modalContent, modal } = useContext(ModalContext);
  if (modal) {
    return ReactDOM.createPortal(
      <div
        className="fixed top-0 left-0 flex items-center justify-center w-full h-screen"
        style={{ background: 'rgba(0,0,0,0.4)', zIndex: MODAL_IDX }}
      >
        <div className="relative flex flex-col items-start p-5 text-lg text-gray-800 rounded md:ml-60">
          {modalContent}
        </div>
      </div>,
      document.querySelector('#modal-root') as HTMLElement
    );
  } else return null;
};
