type Intent = 'primary' | 'secondary';
type Size = 'sm' | 'md' | 'lg';
interface IconButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  intent?: Intent;
  size?: Size;
}

export default function IconButton({ intent = 'primary', size = 'md', className, ...props }: IconButtonProps) {
  return <button className={className} {...props} />;
}
