import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  BrainSvg,
  CancelSVG,
  GiftSvg,
  GroupSvg,
  HomeSvg,
  LeafSvg,
  LOGOSVG,
  MgnfySvg,
  MgSvg,
  MiracleSvg,
  MoonSvg,
  SetSvg,
  WeightSvg,
} from '../assets';
import IconButton from './IconButton';
import QRCODE from '../assets/images/qrcode.png';
import { useTranslation } from 'react-i18next';

const Navigate = ({ onClickMenu }: { onClickMenu?: () => void }) => {
  const { t } = useTranslation();
  const [showQR, setShowQR] = useState(false);
  /** 이용하기 */
  const onClickOpenQR = () => {
    setShowQR(true);
  };
  /** 닫기 */
  const onClickCloseQR = () => {
    setShowQR(false);
  };

  return (
    <nav className="relative flex flex-col w-[240px] max-md:hidden h-full gap-[34px] overflow-y-auto no-scrollbar  p-[20px] bg-N9/60">
      <div className="flex flex-col w-full gap-[34px]">
        <LOGOSVG height={200} />
        <NavItem icon={<HomeSvg />} name={t('navigator.home')} path={'/app/home'} />
        <NavItem icon={<MgnfySvg />} name={t('navigator.search')} path={'/app/search'} />
        <NavItem icon={<GroupSvg />} name={t('navigator.profile')} path={'/app/profile'} />
        <NavItem icon={<MiracleSvg />} name={t('navigator.minit_miracle')} path={'/app/category/minit_miracle'} />
        <NavItem icon={<MoonSvg />} name={t('navigator.sleep')} path={'/app/category/sleep'} />
        <NavItem icon={<WeightSvg />} name={t('navigator.diet')} path={'/app/category/diet'} />
        <NavItem icon={<BrainSvg />} name={t('navigator.concentration')} path={'/app/category/concentration'} />
        <NavItem icon={<LeafSvg />} name={t('navigator.stress')} path={'/app/category/stress'} />
        <NavItem icon={<MgSvg />} name={t('navigator.experience')} path={'/app/category/experience'} />
        <NavItem icon={<SetSvg />} name={t('navigator.setting')} path={'/app/setting'} />

        <div className="flex flex-col w-full gap-4">
          <button onClick={onClickOpenQR} className="w-full h-[56px] rounded-[20px] bg-N5 text-N9 font-bold">
            {t('navigator.useApp')}
          </button>
          <NavLink
            to={'/app/gift'}
            className={({ isActive, isPending }) =>
              isPending
                ? ''
                : isActive
                  ? 'w-full border border-N5 bg-N5 rounded-[20px] h-[56px] text-N9 font-bold flex items-center justify-center gap-[5.7px]'
                  : 'w-full border border-N5 bg-transparent rounded-[20px] h-[56px] text-N3 font-bold flex items-center justify-center gap-[5.7px]'
            }
          >
            <div className="flex items-center gap-[12px] font-semibold text-lg">
              <GiftSvg />
              <span>{t('navigator.gift')}</span>
            </div>
          </NavLink>
        </div>

        <div className="flex flex-col items-start gap-[11px] justify-end w-full h-full text-N6">
          <Link onClick={onClickMenu} to="/app/terms-of-use">
            {t('navigator.useTerm')}
          </Link>
          <Link onClick={onClickMenu} to="/app/privacy-policy">
            {t('navigator.privatePolicy')}
          </Link>
        </div>
      </div>
      <div
        className={`fixed bottom-0 left-0 w-[240px] h-[280px] bg-N0 flex flex-col gap-4 rounded-t-xl p-4 transition-transform duration-300 ease-out ${
          showQR ? 'transform translate-y-0' : 'transform translate-y-[280px]'
        }`}
      >
        <div className="flex justify-end w-full">
          <IconButton onClick={onClickCloseQR}>
            <CancelSVG />
          </IconButton>
        </div>
        <div className="flex justify-center w-full">
          <p className="text-sm text-center">{t('qrCodePopup.scanQR')}</p>
        </div>
        <div className="flex justify-center">
          <img src={QRCODE} className="w-[150px] h-[150px] object-cover" />
        </div>
      </div>
    </nav>
  );
};

function NavItem({ icon, name, path }: { icon: React.ReactElement; name: string; path: string }) {
  return (
    <NavLink to={path} className={({ isActive, isPending }) => (isPending ? '' : isActive ? 'text-N0' : 'text-N5')}>
      <div className="flex items-center gap-[12px] font-semibold text-lg">
        {icon}
        <span>{name}</span>
      </div>
    </NavLink>
  );
}

export default Navigate;
