// export default function (duration: number) {
//   const hour = Math.floor(duration / 3600);
//   const min = Math.floor((duration % 3600) / 60);
//   const sec = Math.floor(duration % 60); // 소수점을 제거합니다.

//   // 시간, 분, 초가 10보다 작으면 앞에 '0'을 붙여줍니다.
//   const formattedHour = hour < 10 ? `0${hour}` : hour;
//   const formattedMin = min < 10 ? `0${min}` : min;
//   const formattedSec = sec < 10 ? `0${sec}` : sec;

//   // 포맷팅된 시간 문자열을 반환합니다.
//   return `${formattedHour}:${formattedMin}:${formattedSec}`;
// }
export default function formatDurationDisplay(duration: number) {
  const hour = Math.floor(duration / 3600);
  const min = Math.floor((duration % 3600) / 60);
  const sec = Math.floor(duration % 60); // 소수점을 제거합니다.

  // 시간이 1 이상이면 시간을 포함한 형식으로 반환합니다.
  if (hour > 0) {
    // 시간, 분, 초가 10보다 작으면 앞에 '0'을 붙여줍니다.
    const formattedHour = hour < 10 ? `0${hour}` : hour;
    const formattedMin = min < 10 ? `0${min}` : min;
    const formattedSec = sec < 10 ? `0${sec}` : sec;
    return `${formattedHour}:${formattedMin}:${formattedSec}`;
  }

  // 시간이 0인 경우 분과 초만 반환합니다.
  const formattedMin = min < 10 ? `0${min}` : min;
  const formattedSec = sec < 10 ? `0${sec}` : sec;
  return `${formattedMin}:${formattedSec}`;
}
